import React from 'react';
import { withTranslation } from "react-i18next";
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';

const SettlementReceipt = (props) => {

    const adalipaUtil = new AdalipaUtil();

    return (
        <React.Fragment>
            <div style={receiptStyle.main}>
                <img src="assets/layout/images/adalipa_logo_01.png" alt="Logo" style={receiptStyle.logo} />
                <p style={receiptStyle.header}>{props.t("Settlement Receipt").toUpperCase()}</p>
                <table style={receiptStyle.table}>
                    <thead>
                        <tr>
                            <th style={receiptStyle.tableHeaderKey}></th>
                            <th style={receiptStyle.tableHeaderValue}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={receiptStyle.columnKey}>{props.t("Time")}:</td>
                            <td style={receiptStyle.columnValue}>{adalipaUtil.dateTimeFormat(props.receiptData.settledTime)}</td>
                        </tr>
                        <tr>
                            <td style={receiptStyle.columnKey}>{props.t("Receipt")}:</td>
                            <td style={receiptStyle.columnValue}>{props.receiptData.transactionId}</td>
                        </tr>
                        <tr>
                            <td style={receiptStyle.columnKey}>{props.t("Account")}:</td>
                            <td style={receiptStyle.columnValue}>{props.receiptData.accountNumber}</td>
                        </tr>
                        <tr>
                            <td style={receiptStyle.columnKey}>{props.t("Account Name")}:</td>
                            <td style={{...receiptStyle.columnValue, fontSize: 7}}>{props.receiptData.accountName}</td>
                        </tr>
                        <tr>
                            <td style={receiptStyle.columnKey}>{props.t("Amount")}:</td>
                            <td style={receiptStyle.columnValue}>{adalipaUtil.amountFormat(props.receiptData.amount)}</td>
                        </tr>
                        <tr>
                            <td style={receiptStyle.columnKey}>{props.t("Charges")}:</td>
                            <td style={receiptStyle.columnValue}>{adalipaUtil.amountFormat(props.receiptData.charges)}</td>
                        </tr>
                        <tr>
                            <td style={receiptStyle.columnKey}>{props.t("Details")}:</td>
                            <td style={{...receiptStyle.columnValue, fontSize: 7}}>{props.receiptData.details}</td>
                        </tr>
                        <tr>
                            <td style={receiptStyle.columnKey}>{props.t("Status")}:</td>
                            <td style={receiptStyle.columnValue}>{props.receiptData.LipaNasiResponse}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th style={receiptStyle.tableHeaderKey}></th>
                            <th style={receiptStyle.tableHeaderValue}></th>
                        </tr>
                    </tfoot>
                </table>
                <p style={receiptStyle.summery}>
                    <small>Thanks for working with us!</small>
                    <br/><small><strong>AdaLipa</strong> Powered By <strong>LipaNasi</strong></small>
                    <br/>
                    <br/>info@adalipa.co.tz
                    <br/>sales@adalipa.co.tz
                </p>
            </div>
        </React.Fragment>
    );
}

const receiptStyle = ({
    main: {fontSize: "9px", fontFamily: 'Times New Roman', width: "200px", height: "200px"},
    logo: {maxWidth:" 30%", width: "30%", marginTop: "5%", marginLeft: "35%", textAlign: "center", alignContent: "center"},
    header: {textAlign: "center", alignContent: "center"},
    table: {width: "200px", maxWidth: "200px", padding: "2px", marginLeft: "5px"},
    tableHeaderKey: {borderTop: "1px dashed black", borderCollapse: "collapse", width: "35%", maxWidth: "35%", textAlign: "left", wordBreak: "break-all"},
    tableHeaderValue: {borderTop: "1px dashed black", borderCollapse: "collapse", width: "65%", maxWidth: "65%", textAlign: "right", wordBreak: "break-all"},
    columnKey: {fontSize: 8, fontWeight: "bold", width: "35%", maxWidth: "35%", textAlign: "left", wordBreak: "break-all"},
    columnValue: {width: "65%", maxWidth: "65%", textAlign: "right", wordBreak: "break-all"},
    summery: {textAlign: "center", alignContent: "center"}
});

export default withTranslation()(SettlementReceipt);