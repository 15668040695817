import React ,{ Component } from "react";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import jsPDF from 'jspdf';
import { WalletService } from "../../service/WalletService";
import { ManagerialService } from "../../service/ManagerialService";
import { Dialog } from "primereact/dialog";
import { Calendar } from 'primereact/calendar';
import { AdalipaUtil } from "../../Utilities/AdalipaUtil";
import { ClientService } from "../../service/ClientService";

class DailyTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dataTableValue:[],
            pdfData:[],
            excelData:[],
            institutios: [],
            institutionId: 0,
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            layout: 'list',
            sortOptions: [
                {label: 'Newest First', value: '!year'},
                {label: 'Oldest First', value: 'year'},
                {label: 'Brand', value: 'brand'}
            ],
            roadId: 0,
            districtId: 0,
            zoneId: 0,
            cellId: 0,

            dateFrom: '',
            dateTo: '',
            reqTime:'',
            Status: null,
            sortOrder: -1,
            sortField: 'id',
            loading: false,
            displayReceipt: false,
            isAdmin: false,
            CashAllowed: true,
            userEmail: '',
            error: "",
            displayDateFilter: false
        }
         this.walletService = new WalletService();
         this.managerialService = new ManagerialService();
         this.clientService = new ClientService();
         this.adalipaUtil = new AdalipaUtil();

         this.onSortChange = this.onSortChange.bind(this);
         this.onPageChange = this.onPageChange.bind(this);
         this.amountTemplate = this.amountTemplate.bind(this);
         this.institutionChargesTemplate = this.institutionChargesTemplate.bind(this);
         this.chargesTemplate = this.chargesTemplate.bind(this);
         this.dviChargesTemplate = this.dviChargesTemplate.bind(this);
         this.dialogFooter = this.dialogFooter.bind(this);
         this.actionTemplate = this.actionTemplate.bind(this);
         this.export = this.export.bind(this);

         this.cols = [
            { field: 'reqTime', header: 'Request Time' },
            { field: 'amount', header: 'Amount' },
            { field: 'LipaNasiCharges', header: 'LipaNasi Charges' },
            { field: 'dviCharges', header: 'Adalipa Charges' },
            { field: 'instCharges', header: 'Client Charges' }
            

        ];
        this.excelCols = {header: ['reqTime', 'amount', 'LipaNasiCharges','dviCharges','instCharges']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList() {
        const role = this.props.roles;
        if(role.includes("ADMIN")){
            this.setState({isAdmin:true});
        }
       
        let {dateFrom, dateTo, pageIndex, pageSize, sortField, sortOrder} = this.state;
        const reqFr = this.adalipaUtil.cleanDateFormat(dateFrom);
        const reqTo = this.adalipaUtil.cleanDateFormat(dateTo);
        this.setState({loading: true});
    
        return this.walletService.getDailyTransactions({dateFrom: reqFr, dateTo: reqTo, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d ,reqTime: this.adalipaUtil.timestampFormat(d.reqTime)
            }));
         
            this.setState({dataTableValue: dataContent});

            this.setState({
                pageIndex: data.pageable.pageNumber,
                pageSize: data.pageable.pageSize,
                totalPages: data.DataTabletotalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            const pdfOptions = data.content.map(d => ({
                ...d ,reqTime: this.adalipaUtil.timestampFormat(d.reqTime)
            }));
            this.setState({pdfData: pdfOptions});
            
            const excelOptions = data.content.map(d => ({
                ...d ,reqTime: this.adalipaUtil.timestampFormat(d.reqTime)
            }));
            this.setState({excelData: excelOptions});
        })
        .catch(error =>{
          this.setState({error: error.message});
        });

        
    }

    componentDidMount() {
        this.getDataList();
        // if(!this.state.isAdmin){
        //    this.getCollectionStatus();
        // }
    }
    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            });
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }   

    onDateFilter() {
        this.setState({displayDateFilter: false});
        this.getDataList();
    }

    clearDateFilter = () => {
        this.setState({
            reqTimeFrom: "",
            reqTimeTo: ""
        }, () => {
            this.getDataList()
        });
    }

    dialogFooter = () => <div className="p-grid">
        <div className="p-col-6 p-text-left">
            <Button type="button" label="Dismiss" onClick={() => this.setState({displayDateFilter: false})} icon="pi pi-times-circle" className="p-button-warning" />
        </div>
    </div>;

    dateTemplate(rowData, column) {
        
            return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['reqTime']));  
        
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Button type="button" onClick={() => this.onPrinterClick(rowData)} icon="pi pi-print" className="p-button p-button-sm p-button-info" style={{padding: "0px"}} title="Print"/>
        </div>;
    }

   

    amountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['amount']);
    }
    chargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['LipaNasiCharges']);
    }
    dviChargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['dviCharges']);
    }
    institutionChargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['instCharges']);
    }

    export() {
        this.dt.exportCSV();
    }
    
    render() { 

        let header = (
            <div style={{textAlign:'left'}}>
                <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
                <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("Dayily Collection List", this.excelCols, this.state.excelData)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
                <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("Daily Collection List", this.exportColumns, this.state.pdfData)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
            </div>
        );
        let dateFilter = <div style={{padding: "0px", margin: "0px"}}>
            <Link to="#" onClick={() => this.setState({displayDateFilter: true})}><i className="pi pi-calendar" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>{this.props.t("Filter")}</small></Link>&nbsp;&nbsp;
            <Link to="#" onClick={() => this.clearDateFilter()}><i className="pi pi-times-circle" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>{this.props.t("Clear")}</small></Link>
        </div>
       return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('Daily Collections')} </h2> </div>
                        
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="reqTime" body={this.dateTemplate} header={this.props.t("Request Time")} sortable={true} filter={true} filterElement={dateFilter}/>
                            <Column field="count" header={this.props.t("Transaction Count")} sortable={true} filter={false}/>
                            <Column field="amount" header={this.props.t("Amount")} body={this.amountTemplate} sortable={true} filter={false}/>
                            <Column field="LipaNasiCharges" header={this.props.t("LipaNasi Charges")} body={this.chargesTemplate} sortable={true} filter={false}/>
                            <Column field="dviCharges" header={this.props.t("Adalipa Charges")} body={this.dviChargesTemplate} sortable={true} filter={false}/>
                            <Column field="instCharges" header={this.props.t("Client Charges")} body={this.institutionChargesTemplate} sortable={true} filter={false}/>
                        </DataTable>
                  
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.displayDateFilter} className="p-col-12 p-md-6 p-lg-6 p-md-offset-1 p-lg-offset-2" modal onHide={() => this.setState({displayDateFilter: false})}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12 p-text-center">
                        <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("Start From")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeFrom" placeholder={this.props.t("Choose Date")} value={this.state.dateFrom} onChange={(e) => this.setState({dateFrom: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("End To")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeTo" placeholder={this.props.t("Choose Date")} value={this.state.dateTo} onChange={(e) => this.setState({dateTo: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-5 p-md-2">
                                        <Button type="button" label="Filter" icon="pi pi-filter" onClick={() => this.onDateFilter()} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
 
export default withTranslation()(DailyTransactions);