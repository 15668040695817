import React, { Component } from 'react';
import { AppFooter } from '../AppFooter';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Link, Redirect } from 'react-router-dom';
import { ACCESS_TOKEN, FULL_NAME, EMAIL, ROLES, INSTITUTE_NAME, CATEGORY, LipaNasi_ACCOUNT, HEADER_BALANCES, INSTITUTE_STATUS, INSTITUTE_ID, DISTRICT_ID, BUSINESS_TYPE } from '../constants';
import { getCurrentUser, login } from '../service/LoginService';
import { ClientService } from '../service/ClientService';
import BeatLoader from "react-spinners/BeatLoader";
import paymentImg from '../assets/layout/images/pay2.png';
import './Login.css';
import i18n from "../i18n"
import { BackgroundStyle } from 'quill';

export default class Login extends Component {s
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            fullName: '',
            failed: null,
            alert: '',
            loading: false
        };
        this.clientService = new ClientService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.goToGalaxy = this.goToGalaxy.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        const script = document.createElement("script");
        script.src = "https://softwaregalaxy.co.tz/assets/sso/main.js";
        script.async = true;
        document.body.appendChild(script);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        login(this.state.email, this.state.password)
            .then(response => {
                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                this.setState({ failed: false });
                this.setState({ alert: "You're successfully logged in!" });
                this.getUserInfo();
            }).catch(error => {
                this.setState({ loading: false });
                this.setState({ failed: true });
                this.setState({ alert: error.error_description });
            });
    }

    getUserInfo() {
        getCurrentUser()
            .then(response => {
                localStorage.setItem(FULL_NAME, response.fullName);
                localStorage.setItem(EMAIL, response.username);
                localStorage.setItem(ROLES, JSON.stringify(response.roles));
                if (response.district !== null) {
                    localStorage.setItem(DISTRICT_ID, response.district.districtName);
                }
                localStorage.setItem(BUSINESS_TYPE, response.institution != null ? response.institution.business.businessType : '');
                localStorage.setItem(INSTITUTE_NAME, response.institution !== null ? response.institution.instituteName : '');
                localStorage.setItem(INSTITUTE_ID, response.institution !== null ? response.institution.id : 0);
                localStorage.setItem(CATEGORY, response.institution !== null ? response.institution.category.institutionType.typeName : '');
                i18n.changeLanguage(response.institution !== null ? (response.institution.category.institutionType.typeName === 'Local Government Authority' ? 'sw' : 'en') : 'en');
                localStorage.setItem("I18N_LANGUAGE", response.institution !== null ? (response.institution.category.institutionType.typeName === 'Local Government Authority' ? 'sw' : 'en') : 'en');
                localStorage.setItem(LipaNasi_ACCOUNT, response.institution !== null ? response.institution.LipaNasiAccount : '');
                localStorage.setItem(INSTITUTE_STATUS, response.institution !== null ? response.institution.status : '');
                this.setState({ fullName: response.fullName });
                this.clientService.getBalances()
                    .then(response => {
                        localStorage.setItem(HEADER_BALANCES, JSON.stringify(response));
                        this.setState({ loading: false });
                        this.props.history.push("/");
                    }).catch(error => {
                        this.setState({ loading: false });
                        console.log("Failed to get header balances");
                        this.setState({ failed: true });
                        this.setState({ alert: error.error_description });
                    })
            }).catch(error => {
                this.setState({ loading: false });
                console.log("Failed to get current user details");
                this.setState({ failed: true });
                this.setState({ alert: error.error_description });
            })
    }

    // goToGalaxy = () => {
    // 	window.location.assign('https://softwaregalaxy.co.tz/_s/auth?callback=https://core.adalipa.co.tz:8443/galaxy/token&xc=26b760c5-4f8b-4a28-917e-a30b0db1d2bf');
    // }

    render() {
        if (this.props.authenticated) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: { from: this.props.location }
                }} />;
        }
        return <div className="login-body">
            <div className="p-grid p-nogutter">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6" style={{ paddingTop: "10%" }}>
                    <div className="login-wrapper">
                        <div className="login-container" style={{ padding: '5%', alignContent: 'center', border: "solid #3B007B", backgroundColor: "#ffffff" }}>
                            <img src="assets/layout/images/lipanasi.jpeg" alt="LipaNasi" className="logo" style={{ width: '30%', height: '30%' }} />
                            {!this.state.loading && <span className="title">Login</span>}
                            {this.state.failed === false &&
                                <div className="p-messages p-component p-messages-success" style={{ margin: '0 0 1em 0', display: 'block' }}>
                                    <div className="p-messages-wrapper">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}.</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            }
                            {this.state.failed === true &&
                                <div className="p-messages p-component p-messages-warn" style={{ margin: '0 0 1em 0', display: 'block' }}>
                                    <div className="p-messages-wrapper">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}.</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            }
                            {this.state.loading &&
                                <div className="p-grid p-fluid" style={{ textAlign: 'center', padding: "2em" }}>
                                    <div className="p-col-12" style={{ textAlign: 'center' }}>
                                        <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                    </div>
                                </div>
                            }
                            {!this.state.loading &&
                                <form onSubmit={this.handleSubmit}>
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12">
                                            <InputText type="email" name="email" placeholder="Username" value={this.state.email} onChange={this.handleInputChange} required />
                                        </div>
                                        <div className="p-col-12">
                                            <InputText type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} required />
                                        </div>
                                        <div className="p-col-6">
                                            <Button type="submit" label="Sign In" icon="pi pi-check" autoFocus={true} />
                                        </div>
                                        <div className="p-col-6 password-container">
                                            <Link to="/forgot/password">Forgot Password?</Link>
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                    <AppFooter />
                </div>
                <div className="p-col-12 p-lg-6 right-side" style={{ paddingTop: '4%' }}>
                    <div className="p-grid" style={{ verticalAlign: 'middle' }}>
                        <div className="p-col-12 p-justify-center" style={{ textAlign: 'center' }}>
                            <h4>Welcome to LipaNasi</h4>
                        </div>

                        {/* <div className="p-col-12" style={{ padding: '0%' }}>
                            <div className="card card-w-title" style={{ textAlign: 'center' }}> */}

                                {/* <img src={paymentImg} alt="payment" className="p-col-12 p-md-6 p-lg-4 p-xl-3 p-justify-center" style={{ width: '100%' }} /> */}
                                <div style={{
                                    backgroundImage: `url(${paymentImg})`,
                                    width: '700px',
                                    height: '500px',
                                    marginLeft: '1.5%',
                                }}>
                                {/* </div>
                                
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
