import React, {Component} from 'react';
import { renderToString } from 'react-dom/server';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from '../service/WalletService';
import { AdalipaUtil } from '../Utilities/AdalipaUtil';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import jsPDF from 'jspdf';
import PaymentReceipt from './PaymentReceipt';
import PdfComponent from './reports/PdfComponent';
import ReactToPrint from 'react-to-print';
import DataComponent from './reports/DataComponent';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';


class Payment extends Component {

    constructor(porps) {
        super(porps);
        this.state = {
            dataTableValue:[],
            excelData:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            accountId: '',
            phoneNumber: '',
            receiptNumber: '',
            client: '',
            Status: null,
            reqTimeFrom: '',
            reqTimeTo: '',
            sortOrder: -1,
            sortField: 'req_time',
            loading: false,
            displayDateFilter: false
        };

        this.walletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.amountTemplate = this.amountTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.export = this.export.bind(this);
        this.dialogFooter = this.dialogFooter.bind(this);

        this.cols = [
            { field: 'reqTime', header: 'Request Time' },
            {field: 'name', header: 'Customer Name'},
            { field: 'accountId', header: 'Reference' },
            { field: 'phoneNumber', header: 'Phone Number' },
            { field: 'receiptNumber', header: 'Receipt Number' },
            { field: 'amount', header: 'Fee Amount' }
        ];
        this.excelCols = {header: ['reqTime','name', 'accountId', 'phoneNumber', 'receiptNumber', 'amount']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList() {
        console.log("Institution Type",this.props.institutionType);
        let {accountId, phoneNumber, receiptNumber, client, reqTimeFrom, reqTimeTo, pageIndex, pageSize, sortField, sortOrder} = this.state;
        const rqFr = this.adalipaUtil.cleanDateFormat(reqTimeFrom);
        const rqTo = this.adalipaUtil.cleanDateFormat(reqTimeTo);
        const paramsData = {reference: accountId, phoneNumber: phoneNumber, receiptNumber: receiptNumber, client: client, reqTimeFrom: rqFr, reqTimeTo: rqTo, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'};
        this.setState({loading: true});        
        return this.walletService.getTransactions(paramsData,this.props.institutionType)
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                pageSize: data.pageable.pageSize,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            const excelOptions = data.content.map(d => ({
                reqTime: d.reqTime, accountId: d.accountId, phoneNumber: d.phoneNumber, receiptNumber: d.receiptNumber, amount: d.amount
            }));
            this.setState({excelData: excelOptions});
        });
    }

    componentDidMount() {
        this.getDataList();
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });   
    }

    onDateFilter() {
        this.setState({displayDateFilter: false});
        this.getDataList();
    }

    clearDateFilter = () => {
        this.setState({
            reqTimeFrom: "",
            reqTimeTo: ""
        }, () => {
            this.getDataList()
        });
    }

    dialogFooter = () => <div className="p-grid">
        <div className="p-col-6 p-text-left">
            <Button type="button" label="Dismiss" onClick={() => this.setState({displayDateFilter: false})} icon="pi pi-times-circle" className="p-button-warning" />
        </div>
    </div>;

    dateTemplate(rowData, column) {
        return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['updatedTime']));
    }
    amountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['amount']);
    }

    actionTemplate(rowData, column) {
        
        return <div style={{whiteSpace: "nowrap"}}>
             <NavLink to={{pathname:"/print/payment/receipts"  ,          
                receiptProps: {
                    instituteName: localStorage.getItem("instituteName"),
                    phoneNumb: rowData["phoneNumber"],
                    receiptNumber: rowData["receiptNumber"],
                    receiptDate: rowData["reqTime"],
                    accountNumber: rowData["accountId"],
                    amount: rowData["amount"],
                    managerPhoneNumber: rowData["managerPhoneNumber"],
                    charges: rowData["charges"],
                }            
             }} exact > 
 <Button type="button" icon="pi pi-print" className="p-button p-button-sm p-button-info" style={{padding: "0px"}} title={this.props.t("Print")}/>
 </NavLink>
            {/* <Button type="button" onClick={() => this.onPrinterClick(rowData)} icon="pi pi-print" className="p-button p-button-sm p-button-info" style={{padding: "0px"}} title={this.props.t("Print")}/> */}
            { this.props.institutionType === "Local Government Authority" && 
                <Button type="button" onClick={() => this.onTransferClick(rowData)} icon="pi pi-arrow-circle-right" className="p-button p-button-sm p-button-danger" style={{padding: "0px"}} title={this.props.t("Transfer")}/>
            }
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

    onPrinterClick(data) {
        this.setState({receiptData: {...data, actualAamount: (data.amount + data.charges)}}, () => {
            const doc = new jsPDF();
            const htmlElement = renderToString(<PaymentReceipt receiptData={this.state.receiptData} />);
            doc.html(htmlElement).then(() => {
                doc.setFont("helvetica");
                doc.setFontSize(2);
                doc.autoPrint();
                doc.output('dataurlnewwindow');
            });
        });
    }

    onTransferClick(data) {
        this.props.history.push("/wallet/lga/pay/transfer/0/" + data["id"]);
    }

    render() {

        let header = (
            <div style={{textAlign:'left'}}>
                <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
                <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("Payments", this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
                <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("List of Payments", this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
                {/* <PdfComponent /> */}
                {/* <ReactToPrint
              content={() => this.componentRef}
              trigger={() => <button className="btn btn-primary">Print to PDF!</button>}
            />
            <DataComponent ref={(response) => (this.componentRef = response)} /> */}
            {/* {console.log("ImnstituteName",localStorage.getItem("instituteName"))} */}

{/* <NavLink
     to={{
        pathname:"/print/payment/receipts"  
        // render={()=> <PdfComponent institutionName={localStorage.getItem("instituteName")}/>}
        render={() => <PdfComponent message={dataToPass} />}
        exact        
       >Preview Question
</NavLink> */}

            </div>
            
        );
        let dateFilter = <div style={{padding: "0px", margin: "0px"}}>
            <Link to="#" onClick={() => this.setState({displayDateFilter: true})}><i className="pi pi-calendar" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>{this.props.t("Filter")}</small></Link>&nbsp;&nbsp;
            <Link to="#" onClick={() => this.clearDateFilter()}><i className="pi pi-times-circle" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>{this.props.t("Clear")}</small></Link>
        </div>
        let accountIdFilter = <InputText type="text" name="accountId" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.accountId} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let receiptNumberFilter = <InputText type="text" name="receiptNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.receiptNumber} onChange={this.handleInputChange}/>
        let clientFilter = <InputText type="text" name="client" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.client} onChange={this.handleInputChange}/>
        let nameFilter = <InputText type="text" name="name" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.client} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('List of Payments')}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/payments" style={{float: "right"}}><strong style={{color: '#3B007B'}}>{this.props.t('LipaNasi Account')}: {this.props.LipaNasiAccount}</strong></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="reqTime" body={this.dateTemplate} header={this.props.t("Request Time")} sortable={true} filter={true} filterElement={dateFilter}/>
                            {this.props.institutionType === "Local Government Authority" && <Column field="name" header={this.props.t("Customer Name")} sortable={true} filter={true} filterElement={nameFilter} filterPlaceholder="Contains"/>}
                            <Column field="accountId" header={this.props.t("Reference")} sortable={true} filter={true} filterElement={accountIdFilter} filterPlaceholder="Contains"/>
                            <Column field="phoneNumber" header={this.props.t("Phone Number")} sortable={true} filter={true} filterElement={phoneNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="receiptNumber" header={this.props.t("Receipt Number")} sortable={true} filter={true} filterElement={receiptNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="client" header={this.props.t("Partner")} sortable={true} filter={true} filterElement={clientFilter} filterPlaceholder="Contains"/>                            
                            <Column field="amount" header={this.props.t("Amount")} body={this.amountTemplate} sortable={true} filter={false}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', fontSize: 'small', width: "10em"}}/>
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Dialog visible={this.state.displayDateFilter} className="p-col-12 p-md-6 p-lg-6 p-md-offset-1 p-lg-offset-2" modal onHide={() => this.setState({displayDateFilter: false})}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12 p-text-center">
                        <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("Start From")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeFrom" placeholder={this.props.t("Choose Date")} value={this.state.reqTimeFrom} onChange={(e) => this.setState({reqTimeFrom: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("End To")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeTo" placeholder={this.props.t("Choose Date")} value={this.state.reqTimeTo} onChange={(e) => this.setState({reqTimeTo: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-5 p-md-2">
                                        <Button type="button" label="Filter" icon="pi pi-filter" onClick={() => this.onDateFilter()} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
export default withTranslation()(Payment)
