import React,{ Component } from "react";
import { renderToString } from 'react-dom/server';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";
import jsPDF from 'jspdf';
import { WalletService } from "../../service/WalletService";
import { ManagerialService } from "../../service/ManagerialService";
import { Dialog } from "primereact/dialog";
import { Calendar } from 'primereact/calendar';
import { AdalipaUtil } from "../../Utilities/AdalipaUtil";
import { Dropdown } from "primereact/dropdown";
import { ClientService } from "../../service/ClientService";

class GeneralTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dataTableValue:[],
            pdfData:[],
            excelData:[],
            institutios: [],
            institutionId: 0,
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            layout: 'list',
            sortOptions: [
                {label: 'Newest First', value: '!year'},
                {label: 'Oldest First', value: 'year'},
                {label: 'Brand', value: 'brand'}
            ],
            roadId: 0,
            districtId: 0,
            zoneId: 0,
            cellId: 0,

            accountId:'',
            receiptNumber:'',
            dateFrom: '',
            dateTo: '',
            phoneNumber:'',
            reqTime:'',
            // institutionId:0,

            
            Status: null,
            sortOrder: -1,
            sortField: 'id',
            loading: false,
            // receiptData: {crisesTime: "", reporterName: "", phoneNumber: "", numberOfOccurrence: 0, crisesStatus: "",institutionName: "",createdBy: ""},
            displayReceipt: false,
            isAdmin: false,
            CashAllowed: true,
            userEmail: '',
            error: "",
            displayDateFilter: false
         }

         this.walletService = new WalletService();
         this.managerialService = new ManagerialService();
         this.clientService = new ClientService();
         this.adalipaUtil = new AdalipaUtil();

         this.onSortChange = this.onSortChange.bind(this);
         this.onPageChange = this.onPageChange.bind(this);
         this.handleInputChange = this.handleInputChange.bind(this);
         this.amountTemplate = this.amountTemplate.bind(this);
         this.institutionChargesTemplate = this.institutionChargesTemplate.bind(this);
         this.chargesTemplate = this.chargesTemplate.bind(this);
         this.dviChargesTemplate = this.dviChargesTemplate.bind(this);
         this.handleDropdownChange = this.handleDropdownChange.bind(this);
        // this.actionTemplate = this.actionTemplate.bind(this);
         this.dialogFooter = this.dialogFooter.bind(this);
         this.actionTemplate = this.actionTemplate.bind(this);
         this.export = this.export.bind(this);

         this.cols = [
            { field: 'reqTime', header: 'Request Time' },
            {field:  'institutionName', header: 'Customer Name'},
            { field: 'accountId', header: 'Reference' },
            { field: 'phoneNumber', header: 'Phone Number' },
            { field: 'receiptNumber', header: 'Receipt Number' },
            { field: 'amount', header: 'Amount' },
            { field: 'charges', header: 'LipaNasi Charges' },
            { field: 'divCharges', header: 'Adalipa Charges' },
            { field: 'instituteCharges', header: 'Client Charges' }
            

        ];
        this.excelCols = {header: ['reqTime', 'institutionName', 'accountId', 'phoneNumber',"receiptNumber", 'amount', 'charges','divCharges','instituteCharges']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList() {
        console.log("ROLE LIST",this.props.roles);
        const role = this.props.roles;
        if(role.includes("ADMIN")){
            this.setState({isAdmin:true});
        }
       
        let {institutionId,accountId,receiptNumber,dateFrom, dateTo, phoneNumber, pageIndex, pageSize, sortField, sortOrder} = this.state;
        const reqFr = this.adalipaUtil.cleanDateFormat(dateFrom);
        const reqTo = this.adalipaUtil.cleanDateFormat(dateTo);
        this.setState({loading: true});
    
        return this.walletService.getAllTransactions({institutionId:institutionId,accountId:accountId, receiptNumber:receiptNumber, dateFrom: reqFr, dateTo: reqTo, phoneNumber: phoneNumber, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.paymentPage.content.map(d => ({
                ...d ,reqTime: this.adalipaUtil.timestampFormat(d.reqTime),institutionName: d.institution.instituteName
            }));
            //console.log("Paym6m6m6m6m6",data.paymentList);
            // data.paymentList.map(d => {
            //     console.log("export Data",d.institution.instituteName);  
            // })
            this.setState({dataTableValue: dataContent});

            this.setState({
                pageIndex: data.paymentPage.pageable.pageNumber,
                pageSize: data.paymentPage.pageable.pageSize,
                totalPages: data.paymentPage.DataTabletotalPages,
                numberOfElements: data.paymentPage.numberOfElements,
                totalElements: data.paymentPage.totalElements,
                first: (data.paymentPage.pageable.pageNumber * data.paymentPage.totalPages) + 1
            });
            const pdfOptions = data.paymentList.map(d => ({
                ...d ,reqTime: this.adalipaUtil.timestampFormat(d.reqTime),institutionName: d.institution.instituteName
            }));
            console.log("PDF DATA",pdfOptions);
            this.setState({pdfData: pdfOptions});
            
            const excelOptions = data.paymentList.map(d => ({
                ...d ,reqTime: this.adalipaUtil.timestampFormat(d.reqTime),institutionName: d.institution.instituteName
            }));
            this.setState({excelData: excelOptions});
            this.getAllInstitutions();
        })
        .catch(error =>{
          this.setState({error: error.message});
        });

        
    }

    getAllInstitutions= ()=>{
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.clientService.getAllInstitutions(jsonParams)
        .then(resp =>{
           const institutions = resp.map(d => ({
            "name": d.instituteName+"-"+d.id,
            "id": d.id
           }));
           this.setState({institutions: institutions});
        })
    }

    componentDidMount() {
        this.getDataList();
        // if(!this.state.isAdmin){
        //    this.getCollectionStatus();
        // }
    }
    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            });
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    /**
     * Handles the input change event.
     *
     * @param {Object} event - The input change event object.
     * @return {void} This function does not return a value.
     */
    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });
    }

    onDateFilter() {
        this.setState({displayDateFilter: false});
        this.getDataList();
    }

    clearDateFilter = () => {
        this.setState({
            reqTimeFrom: "",
            reqTimeTo: ""
        }, () => {
            this.getDataList()
        });
    }

    dialogFooter = () => <div className="p-grid">
        <div className="p-col-6 p-text-left">
            <Button type="button" label="Dismiss" onClick={() => this.setState({displayDateFilter: false})} icon="pi pi-times-circle" className="p-button-warning" />
        </div>
    </div>;

    dateTemplate(rowData, column) {
        
            return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['reqTime']));  
        
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Button type="button" onClick={() => this.onPrinterClick(rowData)} icon="pi pi-print" className="p-button p-button-sm p-button-info" style={{padding: "0px"}} title="Print"/>
        </div>;
    }

    amountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['amount']);
    }
    chargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['charges']);
    }
    dviChargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['divCharges']);
    }
    institutionChargesTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['instituteCharges']);
    }

    export() {
        this.dt.exportCSV();
    }


    render() { 
        let header = (
            <div style={{textAlign:'left'}}>
                <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
                <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("Collection List", this.excelCols, this.state.excelData)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
                <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("Collection List", this.exportColumns, this.state.pdfData)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
            </div>
        );
        let dateFilter = <div style={{padding: "0px", margin: "0px"}}>
            <Link to="#" onClick={() => this.setState({displayDateFilter: true})}><i className="pi pi-calendar" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>{this.props.t("Filter")}</small></Link>&nbsp;&nbsp;
            <Link to="#" onClick={() => this.clearDateFilter()}><i className="pi pi-times-circle" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>{this.props.t("Clear")}</small></Link>
        </div>
        let accountIdFilter = <InputText type="text" name="accountId" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.accountId} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let receiptNumberFilter = <InputText type="text" name="receiptNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.receiptNumber} onChange={this.handleInputChange}/>
        // let clientFilter = <InputText type="text" name="client" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.client} onChange={this.handleInputChange}/>
        let institutionNameFilter = <Dropdown name="institutionId" style={{width: '100%'}} placeholder={this.props.t("Choose Institution")} filterPlaceholder={this.props.t("Search") + " " + this.props.t("Institution")} options={this.state.institutions} value={this.state.institutionId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('Collection List')} </h2> </div>
                            {/* <div className="p-col-8 p-md-8">
                                <Link to="/wallet/lga/add/crises" style={{float: "right"}}><Button label={this.props.t("Add Crises")} icon="pi pi-plus"/></Link>                                
                            </div> */}
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="reqTime" body={this.dateTemplate} header={this.props.t("Request Time")} sortable={true} filter={true} filterElement={dateFilter}/>
                            <Column field="accountId" header={this.props.t("Reference")} sortable={true} filter={true} filterElement={accountIdFilter} filterPlaceholder="Contains"/>
                            <Column field="phoneNumber" header={this.props.t("Phone Number")} sortable={true} filter={true} filterElement={phoneNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="receiptNumber" header={this.props.t("Receipt Number")} sortable={true} filter={true} filterElement={receiptNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="institutionName" header={this.props.t("Institution Name")} sortable={true} filter={true} filterElement={institutionNameFilter} filterPlaceholder="Contains"/>                            
                            <Column field="amount" header={this.props.t("Amount")} body={this.amountTemplate} sortable={true} filter={false}/>
                            <Column field="charges" header={this.props.t("LipaNasi Charges")} body={this.chargesTemplate} sortable={true} filter={false}/>
                            <Column field="divCharges" header={this.props.t("Adalipa Charges")} body={this.dviChargesTemplate} sortable={true} filter={false}/>
                            <Column field="instituteCharges" header={this.props.t("Client Charges")} body={this.institutionChargesTemplate} sortable={true} filter={false}/>
                            {/* <Column field="createdBy" header={this.props.t("Created By")} sortable={true} filter={false} filterElement={createdByFilter} filterPlaceholder="Contains"/> */}
                            {/* <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', fontSize: 'small', width: "5em"}}/> */}
                        </DataTable>
                        {/* <Paginator currentPageReportTemplate={"Data " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator> */}
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#3B007B"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.displayDateFilter} className="p-col-12 p-md-6 p-lg-6 p-md-offset-1 p-lg-offset-2" modal onHide={() => this.setState({displayDateFilter: false})}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12 p-text-center">
                        <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("Start From")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeFrom" placeholder={this.props.t("Choose Date")} value={this.state.dateFrom} onChange={(e) => this.setState({dateFrom: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("End To")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeTo" placeholder={this.props.t("Choose Date")} value={this.state.dateTo} onChange={(e) => this.setState({dateTo: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-5 p-md-2">
                                        <Button type="button" label="Filter" icon="pi pi-filter" onClick={() => this.onDateFilter()} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
 
export default withTranslation()(GeneralTransactions);