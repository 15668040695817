import { ACCESS_TOKEN } from '../constants';

export const loginRequest = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
        //'Authorization': 'Basic ' + btoa('data:admin@100%'),
    })

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const postForms = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
    })
    
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const postFiles = (options) => {
    const headers = new Headers({
    })
    
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const postRequest = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const getRequest = (options) => {
    const headers = new Headers({})
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const getRequestWithSignal = (options) => {
    const headers = new Headers({})
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const getRequestTwo = async function fetchData(options) {
    const headers = new Headers({})
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    try {
      const response = await fetch(options.url, options);
      
      if (!response.ok) {
        if(response.status === 401) {
            localStorage.clear();
            window.location.href = "/#/login";
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json()
      // console.log(data);
      return data;
    
    } catch (error) {
      console.error('Error fetching data1:', error);
    }
  }



export const downloadGetRequest = (options) =>{
    const headers = new Headers({})
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    return fetch(options.url, options)    
}

export const queryParams = params => {
    if (!params)
        return "";

    let out = Object.keys(params).map((value, key) => {
        if (key === 0)
            return `?${value}=${params[value]}`;
        else
            return `&${value}=${params[value]}`;
    })
    return out.join("");
}