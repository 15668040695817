import React, { Component } from 'react';
import i18n from './i18n';
import classNames from 'classnames';
import { AppTopbar } from './AppTopbar';
import { AppBreadcrumb } from './AppBreadcrumb';
import { AppMenu } from './AppMenu';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { ProgressBar } from 'primereact/components/progressbar/ProgressBar';
import { ACCESS_TOKEN, ROLES, INSTITUTE_NAME, FULL_NAME, CATEGORY, LipaNasi_ACCOUNT, HEADER_BALANCES,INSTITUTE_ID, DISTRICT_ID, BUSINESS_TYPE } from './constants';
import { Redirect } from 'react-router-dom';
import 'primereact/resources/primereact.min.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';
import HomePage from './home/HomePage';
import Login from './pages/Login';
import AddClient from './clients/AddClient';
import { AddSubAdmin } from './clients/AddSubAdmins'
import { ListManagers } from './clients/ListManagers';
import AddFeeType from './clients/AddFeeType';
import AddLevelFee from './clients/AddLevelFee';
import AddCourse from './clients/AddCourse';
import AddLevel from './clients/AddLevel';
import { ListClients } from './clients/ListClients';
import { FeeTypes } from './clients/FeeTypes';
import { LevelFee } from './clients/LevelFee';
import { Courses } from './clients/Courses';
import { Levels } from './clients/Levels';
import ListStudents from './students/ListStudents';
import AddStudent from './students/AddStudent';
import AddStudentBatch from './students/AddStudentBatch';
import ListCollections from './wallet/ListCollections';
import AddCollections from './wallet/AddCollections';
import CollectionBalance from './wallet/CollectionBalance';
import CollectionFee from './wallet/CollectionFee';
import Payment from './wallet/Payment';
import DistrictPayment from './wallet/DistrictPayment';
import Settlements from './wallet/balance/Settlements';
import UpdateAccount from './wallet/balance/UpdateAccount';
import UpdateOtherAccount from './wallet/balance/UpdateOtherAccount';
import BalanceSettlement from './wallet/balance/BalanceSettlement';
import { StudentInfo } from './students/StudentInfo';
import { GraduateInfo } from './students/GraduateInfo';
import { ListGraduates } from './students/ListGraduates';
import Documents from './clients/Documents';
import UpdateDocument from './clients/UpdateDocument';
import { ApproveDocuments } from './clients/ApproveDocuments';
import UpdateClient from './clients/UpdateClient';
import { PreClients } from './clients/PreClients';
import AddStaff from './user/AddStaff';
import ListStaffs from './user/ListStaffs';
import { ListUsers } from './user/ListUsers';
import { ListUserRoles } from './user/ListUserRoles';
import { ListPartners } from './user/ListPartners';
import AddPartner from './user/AddPartner';
import ListGroups from './wallet/contribution/ListGroups';
import AddGroup from './wallet/contribution/AddGroup';
import EditGroup from './wallet/contribution/EditGroup';
import ListContributions from './wallet/contribution/ListContributions';
import ListMembers from './wallet/contribution/ListMembers';
import MembersWithNoContributions from './wallet/contribution/MembersWithNoContributions';
import AddMember from './wallet/contribution/AddMember';
import EditMember from './wallet/contribution/EditMember';
import AddCustomer from './wallet/others/AddCustomer';
import EditCustomer from './wallet/others/EditCustomer';
import { ListCustomers } from './wallet/others/ListCustomers';
import { Transactions } from './wallet/others/Transactions';
import SettlementCharges from './wallet/businessIntelligence/SettlementCharges';
import Sms from './wallet/sms/Sms';
import Galaxy from './pages/Galaxy';
import PaymentHistory from './wallet/sms/PaymentHistory';
import FundsTransfer from './wallet/FundsTransfer';
import { Projects } from './wallet/projects/Projects';
import Project from './wallet/projects/Project';
import Tenants from './wallet/projects/Tenants';
import Tenant from './wallet/projects/Tenant';
import { ProjectSales } from './wallet/projects/ProjectSales';
import ProjectSale from './wallet/projects/ProjectSale';
import { ProjectPayments } from './wallet/projects/ProjectPayments';
import Installment from './wallet/projects/Installment';
import { installments } from './wallet/projects/Installments';
import { ArchivedCustomers } from './wallet/others/ArchivedCustomers';
import { OtherBusinessType } from './wallet/others/OtherBusinessType';
import AddBusiness from './wallet/others/AddBusiness';
import Zones from './wallet/lga/Zones';
import Zone from './wallet/lga/Zone';
import Cells from './wallet/lga/Cells';
import Cell from './wallet/lga/Cell';
import CellLeaders from './wallet/lga/CellLeaders';
import CellLeader from './wallet/lga/CellLeader';
import Roads from './wallet/lga/Roads';
import Road from './wallet/lga/Road';
import HouseHolders from './wallet/lga/HouseHolders';
import HouseHolder from './wallet/lga/HouseHolder';
import HouseBills from './wallet/lga/HouseBills';
import HouseHolderBatch from './wallet/lga/HouseHolderBatch';
import HouseHolderPayments from './wallet/lga/reports/HouseHolderPayments';
import { withTranslation } from "react-i18next";
import MonthBills from './wallet/lga/MonthBills';
import ResetingPassword from './user/ResetingPassword';
import ContributionMemberBatch from './wallet/contribution/ContributionMemberBatch';
import PayTransfer from './wallet/lga/PayTransfer';
import PayTransfers from './wallet/lga/PayTransfers';
import ClientInfo from './clients/ClientInfo';
import HouseHolderInfo from './wallet/lga/HouseHolderInfo';
import LgaTenants from './wallet/lga/LgaTenants';
import LgaTenant from './wallet/lga/LgaTenant';
import LgaEmployees from './wallet/lga/LgaEmployees';
import LgaEmployee from './wallet/lga/LgaEmployee';
import LgaSalaries from './wallet/lga/LgaSalaries';
import LgaDisburseSalaries from './wallet/lga/LgaDisburseSalaries';
import CellPayments from './wallet/CellPayments';
import RoadPayments from './wallet/RoadPayments';
import PaymentCharges from './wallet/businessIntelligence/PaymentCharges';
import InstitutionPayments from './wallet/lga/reports/InstitutionPayments';
import LgaAnalyticsReport from './wallet/lga/reports/LgaAnalyticsReport';
import LgaGeneralAnalyticalReport from './wallet/lga/reports/LgaGeneralAnalyticalReport';
import InstitutionData from './wallet/lga/reports/InstitutionData';
import InstitutionsList from './wallet/lga/reports/InstitutionsList';
import ExtraChargesSettlement from './wallet/extracharges/ExtraChargesSettlement';
import PdfComponent from './wallet/reports/PdfComponent';
import CashCollection from './wallet/cash/CashCollection';
import CollectCash from './wallet/cash/CollectCash';
import CashPaymentReference from './wallet/cash/CashPaymentRef';
import PushUssdPaymentList from './wallet/pushUssd/PushUssdPaymentList';
import CreatePushUssd from './wallet/pushUssd/CreatePushUssd';
import LgaTenantsBatch from './wallet/lga/LgaTenantsBatch';
import Crises from './administration/Crises';
import AddCrises from './administration/AddCrises';
import GeneralTransactions from './wallet/reports/GeneralTransactions';
import ChargesReport from './wallet/reports/ChargesReport';
import CrisesInfo from './administration/CrisesInfo';
import AddAgent from './user/agent/AddAgent';
import ListAgents from './user/agent/ListAgents';
import TenantsMonthBills from './wallet/lga/TenantsMonthBills';
import LgaTenantInfo from './wallet/lga/LgaTenantInfo';
import ListWhatsapp from './wallet/whatsapp/ListWhatsapp';
import InitiateWhatsapp from './wallet/whatsapp/InitiateWhatsapp';
import DailyTransactions from './wallet/reports/DailyTransactions';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            currentUser: null,
            loading: false,
            layoutMode: 'static',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            topbarMenuActive: false,
            languageMenuActive: false,
            activeTopbarItem: null,
            darkTheme: false,
            menuActive: false,
            themeColor: 'indigo',
            configDialogActive: false,
            clientType: '',
            headerBalances: localStorage.getItem(HEADER_BALANCES)
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onLanguageMenuButtonClick = this.onLanguageMenuButtonClick.bind(this);
        this.onThemeChange = this.onThemeChange.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.changeMenuMode = this.changeMenuMode.bind(this);
        this.changeMenuColor = this.changeMenuColor.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
        this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
        this.onConfigClick = this.onConfigClick.bind(this);
        this.getMenu = this.getMenu.bind(this);
        this.createMenu();
        this.changeHeaderBalances = this.changeHeaderBalances.bind(this);
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            topbarMenuActive: false,
            languageMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay' && !this.isMobile()) {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        } else {
            if (this.isDesktop())
                this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
            else
                this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onLanguageMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ languageMenuActive: !this.state.languageMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onConfigButtonClick(event) {
        this.configClick = true;
        this.setState({ configDialogActive: !this.state.configDialogActive })
    }

    onConfigCloseClick() {
        this.setState({ configDialogActive: false })
    }

    onConfigClick() {
        this.configClick = true;
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false,
                languageMenuActive: false
            });
        }

        if (!this.configClick) {
            this.setState({ configDialogActive: false });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.configClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeMenuMode(event) {
        this.setState({
            layoutMode: event.menuMode,
            staticMenuDesktopInactive: false,
            overlayMenuActive: false
        });
    }

    changeMenuColor(event) {
        this.setState({ darkTheme: event.darkTheme })
        this.onThemeChange();
    }

    onThemeChange() {
        const themeLink = document.getElementById('theme-css');
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        const themeMode = themeTokens[2];
        const newThemeMode = (themeMode === 'dark') ? 'light' : 'dark';

        this.changeTheme({ originalEvent: null, theme: themeName + '-' + newThemeMode });
    }

    changeTheme(event) {
        this.setState({ themeColor: event.theme.split('-')[0] })
        this.changeStyleSheetUrl('layout-css', event.theme, 'layout');
        this.changeStyleSheetUrl('theme-css', event.theme, 'theme');
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);

        if (value.indexOf('dark') !== -1) {
            this.setState({ darkTheme: true });
        } else {
            this.setState({ darkTheme: false });
        }
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    replaceLink(linkElement, href) {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    createMenu() {
        // this.businessIntelligence = [
        //     {label: this.props.t('Charges'), icon: 'pi pi-fw pi-money-bill', to: '/settlement/charges'}
        // ];
        this.transactions = [
            { label: this.props.t('Push Ussd'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/pushUssd' },
            { label: this.props.t('Deposits'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/payments' },
            { label: this.props.t('Withdrawals'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/settlements' }
        ];
        this.lgaTransactions = [
            { label: this.props.t('Cash'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/cashcollections/0' },
            { label: this.props.t('Push Ussd'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/pushUssd' },
            { label: this.props.t('Deposits'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/payments' },
            { label: this.props.t('Withdrawals'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/settlements' } 
        ]
        this.agentsTransactions = [
            { label: this.props.t('Cash'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/cashcollections/0' },
            { label: this.props.t('Push Ussd'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/pushUssd' },
        ];
        this.lgaAdminReprts = [
            { label: this.props.t('Adalipa Collection Details'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/reports/house-holder-payments' },
            { label: this.props.t('Lga Collection Summary'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/reports/institutional-payments' },
            { label: this.props.t('Lga Analytics Details'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/reports/lga-analytics-report' },
            { label: this.props.t('Lga Analytics Summary'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/reports/lga-general-anlt-report' },
            { label: this.props.t('Collection List'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/report/transacions-list' },
            { label: this.props.t('Daily Report'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/report/daily-report' },
            { label: this.props.t('Charges Summary'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/report/charges-list' },
        ];

        this.administrationReports = [
            { label: this.props.t('Collection List'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/report/transacions-list' },
            { label: this.props.t('Charges Summary'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/report/charges-list' },
        ];

        // this.lgaTwoWaySms = [
        //     { label: this.props.t('Configure TwoWay Sms'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/reports/institutional-payments' },
        //     // {
        //     //     label: this.props.t('Configure TwoWay Sms'), icon: 'pi pi-fw pi-users',
        //     //     items: this.users
        //     // }
        // ]

        this.lgaDistrictReprts = [
            { label: this.props.t('Collection Summary'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/reports/institutional-payments' },
            { label: this.props.t('Analytics Summary'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/reports/lga-general-anlt-report' },
            { label: this.props.t('Crises Report'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/crises/0' }
        ]

        this.lgaStaffReprts = [
            { label: this.props.t('Collection Report'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/reports/house-holder-payments' },
            { label: this.props.t('Analytics Report'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/reports/lga-analytics-report' },
        ];
        this.lgaStaffCrises = [
            { label: this.props.t('Crises List'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/crises/0' },
            // { label: this.props.t('Analytics Report'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/lga/reports/lga-analytics-report' },
        ];
        this.districtManagerTransactions = [
            { label: this.props.t('Collection List'), icon: 'pi pi-fw pi-briefcase', to: '/wallet/report/transacions-list' }
            // { label: this.props.t('Payments'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/district-payments' }
        ];
        this.collections = [
            { label: this.props.t('Collections'), icon: 'pi pi-fw pi-desktop', to: '/wallet/list/collections' },
            { label: this.props.t('Balances'), icon: 'pi pi-fw pi-check-square', to: '/wallet/collection/balances' },
            { label: this.props.t('Transactions'), icon: 'pi pi-fw pi-table', to: '/wallet/collection/fees' }
        ];
        this.users = [
            { label: this.props.t('User Management'), icon: 'pi pi-fw pi-desktop', to: '/user/list/staffs' },
            { label: this.props.t('Agents Management'), icon: 'pi pi-fw pi-desktop', to: '/user/list/agents' },
            { label: this.props.t('Documents'), icon: 'pi pi-fw pi-check-square', to: '/client/list/documents' },
            {
                label: this.props.t('Withdraw Accounts'), icon: 'pi pi-fw pi-table',
                items: [
                    { label: this.props.t('Main Account'), icon: 'pi pi-fw pi-table', to: '/wallet/update/account' },
                    { label: this.props.t('Collections Account'), icon: 'pi pi-fw pi-table', to: '/wallet/update/other/account' }
                ]
            }
        ];

        this.adminMenu = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
            {
                label: this.props.t('Partners Management'), icon: 'pi pi-fw pi-users',
                items: [
                    { label: this.props.t('Add Partner'), icon: 'pi pi-fw pi-desktop', to: '/partner/add/partners' },
                    { label: this.props.t('Partners List'), icon: 'pi pi-fw pi-desktop', to: '/partner/list/partners' }
                ]
            },
            {
                label: this.props.t('Clients Management'), icon: 'pi pi-fw pi-users',
                items: [
                    { label: this.props.t('Add Client'), icon: 'pi pi-fw pi-desktop', to: '/client/add-client' },
                    { label: this.props.t('Clients List'), icon: 'pi pi-fw pi-desktop', to: '/client/list-clients' },
                    { label: this.props.t('Partially Registered'), icon: 'pi pi-fw pi-table', to: '/client/partially/registered' }
                ]
            },
            {
                label: this.props.t('Sub Admin Management'), icon: 'pi pi-fw pi-users',
                items: [
                    { label: this.props.t('Add Manager'), icon: 'pi pi-fw pi-desktop', to: '/manager/add-district-manager' },
                    { label: this.props.t('Manager List'), icon: 'pi pi-fw pi-desktop', to: '/manager/list-managers' }
                ]	
            },
            {
                label: this.props.t('Users Management'), icon: 'pi pi-fw pi-users',
                items: [
                    { label: this.props.t('Users'), icon: 'pi pi-fw pi-money-bill', to: '/user/list/users' },
                    { label: this.props.t('User Roles'), icon: 'pi pi-fw pi-money-bill', to: '/user/roles' }
                ]
            },
            {
                label: this.props.t('Two Way Sms'), icon: 'pi pi-fw pi-users',
                items: [
                    // { label: this.props.t('Users'), icon: 'pi pi-fw pi-money-bill', to: '/user/list/users' },
                    // { label: this.props.t('User Roles'), icon: 'pi pi-fw pi-money-bill', to: '/user/roles' },
                    { label: this.props.t('Configure TwoWay Sms'), icon: 'pi pi-fw pi-briefcase', to: '/twoway/sms/init/sms' },
                    { label: this.props.t('Configure TwoWay Sms'), icon: 'pi pi-fw pi-briefcase', to: '/twoway/sms/questions' }
                ]
            },



            
            {
                label: this.props.t('Transactions'), icon: 'pi pi-fw pi-briefcase',
                items: this.transactions
            }, {
                label: this.props.t('Reports'), icon: 'pi pi-fw pi-briefcase',
                items: this.lgaAdminReprts
            }
            , {
                label: this.props.t('Business Intelligence'), icon: 'pi pi-fw pi-briefcase',
                items: [
                    { label: this.props.t('Settlement Charges'), icon: 'pi pi-fw pi-money-bill', to: '/settlement/charges' },
                    { label: this.props.t('Payment Charges'), icon: 'pi pi-fw pi-money-bill', to: '/payment/charges' },
                ]
            }
        ];

        this.managerMenu = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
            {
                label: this.props.t('Transactions'), icon: 'pi pi-fw pi-briefcase',
                items: this.districtManagerTransactions
            }, {
                label: this.props.t('Reports'), icon: 'pi pi-fw pi-briefcase',
                items: this.lgaDistrictReprts
            }
        ];

        this.agentMenu = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
            {
                label: this.props.t('Transactions'), icon: 'pi pi-fw pi-briefcase',
                items: this.agentsTransactions
            }, {
                label: this.props.t('Authority'), icon: 'pi pi-fw pi-briefcase',
                items: [
                    { label: this.props.t('House Holders'), icon: 'pi pi-fw pi-users', to: '/wallet/lga/house/holders' },
                    { label: this.props.t('Tenants'), icon: 'pi pi-fw pi-users', to: '/wallet/lga/tenants/0' },
                ]
            }
        ]

        this.administrationMenu = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
             {
                label: this.props.t('Reports'), icon: 'pi pi-fw pi-briefcase',
                items: this.administrationReports
            }
        ];

        this.partnerMenu = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
            {
                label: this.props.t('Transactions'), icon: 'pi pi-fw pi-briefcase',
                items: this.transactions
            },
            {
                label: this.props.t('Client Management'), icon: 'pi pi-fw pi-users',
                items: [
                    { label: this.props.t('Add Client'), icon: 'pi pi-fw pi-desktop', to: '/client/add-client' },
                    { label: this.props.t('Clients List'), icon: 'pi pi-fw pi-desktop', to: '/client/list-clients' }
                ]
            }
        ];

        this.colleges = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
            {
                label: this.props.t('Transactions'), icon: 'pi pi-fw pi-briefcase',
                items: this.transactions
            },
            {
                label: this.props.t('Academic Management'), icon: 'pi pi-fw pi-folder-open',
                items: [
                    { label: this.props.t('Courses'), icon: 'pi pi-fw pi-desktop', to: '/courses' },
                    { label: this.props.t('Levels'), icon: 'pi pi-fw pi-check-square', to: '/levels' },
                    { label: this.props.t('Fee Types'), icon: 'pi pi-fw pi-desktop', to: '/fee/types' },
                    { label: this.props.t('Fee Settings'), icon: 'pi pi-fw pi-check-square', to: '/fee/levels' }
                ]
            },
            {
                label: this.props.t('Students Management'), icon: 'pi pi-fw pi-users',
                items: [
                    { label: this.props.t('Students'), icon: 'pi pi-fw pi-desktop', to: '/student/list-students' },
                    { label: this.props.t('Graduates'), icon: 'pi pi-fw pi-check-square', to: '/graduate/list-graduates' }
                ]
            },
            {
                label: this.props.t('Other Collections'), icon: 'pi pi-fw pi-money-bill',
                items: this.collections
            },
            { label: this.props.t('Sms Notification'), icon: 'pi pi-fw pi-comment', to: '/sms/notifications' },
            {
                label: this.props.t('Configuration'), icon: 'pi pi-fw pi-users',
                items: this.users
            }
        ];

        this.schools = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
            {
                label: this.props.t('Transactions'), icon: 'pi pi-fw pi-briefcase',
                items: this.transactions
            },
            {
                label: this.props.t('Academic Management'), icon: 'pi pi-fw pi-folder-open',
                items: [
                    { label: this.props.t('Levels'), icon: 'pi pi-fw pi-check-square', to: '/levels' },
                    { label: this.props.t('Fee Types'), icon: 'pi pi-fw pi-desktop', to: '/fee/types' },
                    { label: this.props.t('Fee Settings'), icon: 'pi pi-fw pi-check-square', to: '/fee/levels' }
                ]
            },
            {
                label: this.props.t('Students Management'), icon: 'pi pi-fw pi-users',
                items: [
                    { label: this.props.t('Students'), icon: 'pi pi-fw pi-desktop', to: '/student/list-students' },
                    { label: this.props.t('Graduates'), icon: 'pi pi-fw pi-check-square', to: '/graduate/list-graduates' }
                ]
            },
            {
                label: this.props.t('Other Collections'), icon: 'pi pi-fw pi-money-bill',
                items: this.collections
            },
            { label: this.props.t('Sms Notification'), icon: 'pi pi-fw pi-comment', to: '/sms/notifications' },
            {
                label: this.props.t('Configuration'), icon: 'pi pi-fw pi-users',
                items: this.users
            }
        ];

        this.contributions = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
            {
                label: this.props.t('Transactions'), icon: 'pi pi-fw pi-briefcase',
                items: this.transactions
            },
            {
                label: this.props.t('Contributions'), icon: 'pi pi-fw pi-money-bill',
                items: [
                    { label: this.props.t('Groups'), icon: 'pi pi-fw pi-users', to: '/wallet/list/groups' },
                    { label: this.props.t('Members'), icon: 'pi pi-fw pi-users', to: '/wallet/list/members' },
                    { label: this.props.t('Contributions'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/list/contributions' },
                    { label: this.props.t('Whatsapp Pay'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/list/whatsapp' }
                ]
            },
            {
                label: this.props.t('Other Collections'), icon: 'pi pi-fw pi-money-bill',
                items: this.collections
            },
            { label: this.props.t('Sms Notification'), icon: 'pi pi-fw pi-comment', to: '/sms/notifications' },
            {
                label: this.props.t('Configuration'), icon: 'pi pi-fw pi-users',
                items: this.users
            }
        ];

        this.lga = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
            {
                label: this.props.t('Transactions'), icon: 'pi pi-fw pi-briefcase',
                items: this.lgaTransactions
            },
            {
                label: this.props.t('Authority'), icon: 'pi pi-fw pi-sitemap',
                items: [
                    { label: this.props.t('Zones'), icon: 'pi pi-fw pi-compass', to: '/wallet/lga/zones' },
                    { label: this.props.t('Cells'), icon: 'pi pi-fw pi-sitemap', to: '/wallet/lga/cells' },
                    { label: this.props.t('Roads'), icon: 'pi pi-fw pi-sitemap', to: '/wallet/lga/roads' },
                    { label: this.props.t('Cell Leaders'), icon: 'pi pi-fw pi-users', to: '/wallet/lga/cell-leaders' },
                    { label: this.props.t('House Holders'), icon: 'pi pi-fw pi-users', to: '/wallet/lga/house/holders' },
                    { label: this.props.t('Tenants'), icon: 'pi pi-fw pi-users', to: '/wallet/lga/tenants/0' },
                    { label: this.props.t('Employees'), icon: 'pi pi-fw pi-users', to: '/wallet/lga/employees' },
                    { label: this.props.t('Transfered Payments'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/lga/pay/transfers' }
                ]
            },
            {
                label: this.props.t('Other Collections'), icon: 'pi pi-fw pi-money-bill',
                items: this.collections
            },
            {
                label: this.props.t('Reports'), icon: 'pi pi-fw pi-briefcase',
                items: this.lgaStaffReprts
            },
            {
                label: this.props.t('Crises'), icon: 'pi pi-fw pi-briefcase',
                items: this.lgaStaffCrises
            },
            { label: this.props.t('Sms Notification'), icon: 'pi pi-fw pi-comment', to: '/sms/notifications' },
            {
                label: this.props.t('Configuration'), icon: 'pi pi-fw pi-users',
                items: this.users
            }
        ];

        this.realestate = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
            {
                label: this.props.t('Transactions'), icon: 'pi pi-fw pi-briefcase',
                items: this.transactions
            },
            {
                label: this.props.t('Real Estate'), icon: 'pi pi-fw pi-money-bill',
                items: [
                    { label: this.props.t('Projects'), icon: 'pi pi-fw pi-users', to: '/wallet/real/estate/projects' },
                    { label: this.props.t('Customers'), icon: 'pi pi-fw pi-users', to: '/wallet/real/estate/customers' },
                    { label: this.props.t('Sales'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/real/estate/sales' },
                    { label: this.props.t('Payments'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/real/estate/payments' }
                ]
            },
            {
                label: this.props.t('Other Collections'), icon: 'pi pi-fw pi-money-bill',
                items: this.collections
            },
            { label: this.props.t('Sms Notification'), icon: 'pi pi-fw pi-comment', to: '/sms/notifications' },
            {
                label: this.props.t('Configuration'), icon: 'pi pi-fw pi-users',
                items: this.users
            }
        ];

        this.other_businesses = [
            { label: this.props.t('Dashboard'), icon: 'pi pi-fw pi-home', to: '/' },
            {
                label: this.props.t('Transactions'), icon: 'pi pi-fw pi-briefcase',
                items: this.transactions
            },
            {
                label: this.props.t('Businesses'), icon: 'pi pi-fw pi-money-bill',
                items: [
                    { label: this.props.t('Businesses'), icon: 'pi pi-fw pi-users', to: '/wallet/businesses' },
                    { label: this.props.t('Customers'), icon: 'pi pi-fw pi-users', to: '/wallet/business/list/customers' },
                    { label: this.props.t('Customer Transactions'), icon: 'pi pi-fw pi-money-bill', to: '/wallet/business/transactions/0' }
                ]
            },
            {
                label: this.props.t('Other Collections'), icon: 'pi pi-fw pi-money-bill',
                items: this.collections
            },
            { label: this.props.t('Sms Notification'), icon: 'pi pi-fw pi-comment', to: '/sms/notifications' },
            {
                label: this.props.t('Configuration'), icon: 'pi pi-fw pi-users',
                items: this.users
            }
        ];
    }

    getMenu = (category) => {
        switch (category) {
            case "Collages":
                return this.colleges;
            case "Schools":
                return this.schools;
            case "Contributions":
                return this.contributions;
            case "Local Government Authority":
                return this.lga;
            case "Real Estate":
                return this.realestate;
            case "Other Businesses":
                return this.other_businesses;
            default:
                return [];
        }
    }

    changeHeaderBalances = () => {
        this.setState({ headerBalances: localStorage.getItem(HEADER_BALANCES) });
    }

    render() {
        // if(!localStorage.getItem(ACCESS_TOKEN) && window.location.href.indexOf("galaxy") < 0) {
        //     return window.location.assign('https://softwaregalaxy.co.tz/_s/auth?callback=https://core.adalipa.co.tz:8443/galaxy/token&xc=26b760c5-4f8b-4a28-917e-a30b0db1d2bf');
        // }
        if (!localStorage.getItem(ACCESS_TOKEN)) {
            return <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />;
        }
        // if(window.location.href.indexOf("galaxy") > 2) {
        // 	localStorage.setItem(ROLES, '[{"id":2,"name":"N/A"}]');
        //     localStorage.setItem(HEADER_BALANCES, JSON.stringify({Fee:0,Sms:JSON.stringify({sms:0,funds:0}),OCs:0}));
        // }
        const layoutClassName = classNames('layout-wrapper', {
            'layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-slim': this.state.layoutMode === 'slim',
            'layout-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-mobile-active': this.state.staticMenuMobileActive,
            'layout-overlay-active': this.state.overlayMenuActive
        });
        const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

        let adminRole = JSON.parse(localStorage.getItem(ROLES)).filter(role => role.name.includes("ADMIN"));
        let managerRole = JSON.parse(localStorage.getItem(ROLES)).filter(role => role.name.includes("DISTRICT_MANAGER"));
        let administrationRole = JSON.parse(localStorage.getItem(ROLES)).filter(role => role.name.includes("ADMINISTRATION"));
        let partnerRole = JSON.parse(localStorage.getItem(ROLES)).filter(role => role.name.includes("PARTNER"));
        let agentRole = JSON.parse(localStorage.getItem(ROLES)).filter(role => role.name.includes("AGENT"));

        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>

                <AppTopbar darkTheme={this.state.darkTheme} onThemeChange={this.onThemeChange}
                    topbarMenuActive={this.state.topbarMenuActive} languageMenuActive={this.state.languageMenuActive} activeTopbarItem={this.state.activeTopbarItem}
                    onMenuButtonClick={this.onMenuButtonClick}
                    onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                    onLanguageMenuButtonClick={this.onLanguageMenuButtonClick}
                    onTopbarItemClick={this.onTopbarItemClick}
                    instituteName={localStorage.getItem(INSTITUTE_NAME)}
                    fullName={localStorage.getItem(FULL_NAME)} lang={i18n} />

                <div className='layout-menu-container' onClick={this.onMenuClick}>
                    <div className="layout-menu-content">
                        <div className="layout-menu-title"><small style={{ color: '#3B007B', fontWeight: 'bold', fontSize: "small" }}>{this.props.t('LipaNasi Account')}: {localStorage.getItem(LipaNasi_ACCOUNT)}</small></div>
                        <AppMenu model={administrationRole.length ? this.administrationMenu :adminRole.length ? this.adminMenu : managerRole.length ? this.managerMenu : agentRole.length ? this.agentMenu: (partnerRole.length ? this.partnerMenu : this.getMenu(localStorage.getItem(CATEGORY)))}
                            onMenuItemClick={this.onMenuItemClick}
                            onRootMenuItemClick={this.onRootMenuItemClick}
                            layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                        <div className="layout-menu-footer">
                            <div className="layout-menu-footer-title">{this.props.t('Anual Balance')}</div>

                            <div className="layout-menu-footer-content">
                                <ProgressBar value={50} showValue={false}></ProgressBar>
                                {this.props.t('Current Balance')}
                                <ProgressBar value={80} showValue={false}></ProgressBar>
                                {this.props.t('Actual Balance')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="layout-content">
                    <AppBreadCrumbWithRouter headerBalances={this.state.headerBalances} businessType={localStorage.getItem(BUSINESS_TYPE)} roles={localStorage.getItem(ROLES)} changeHeaderBalances={this.changeHeaderBalances} lang={i18n} />

                    <div className="layout-content-container">
                        <Route path="/" exact render={(props) => <HomePage headerBalances={localStorage.getItem(HEADER_BALANCES)} roles={localStorage.getItem(ROLES)} category={localStorage.getItem(CATEGORY)} lang={i18n} districtName={localStorage.getItem(DISTRICT_ID)} instituteId={localStorage.getItem(INSTITUTE_ID)} {...props} />}></Route>
                        <Route path="/client/add-client" exact component={AddClient} />
                        <Route path="/client/update-client/:clientid" exact component={UpdateClient} />
                        <Route path="/client/details/:clientid" exact component={ClientInfo} />
                        <Route path="/wallet/lga/house/holder/details/:houseHolderId" exact component={HouseHolderInfo} />
                        <Route path="/wallet/lga/tenantinfo/details/:lgaTenantId" exact component={LgaTenantInfo} />
                        
                        <Route path="/fee/add-fee-type" exact component={AddFeeType} />
                        <Route path="/fee/add-level-fee" exact component={AddLevelFee} />
                        <Route path="/add-course" exact component={AddCourse} />
                        <Route path="/add-level" exact render={(props) => <AddLevel institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/student/add-student" exact render={(props) => <AddStudent institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/student/add-student-batch" exact render={(props) => <AddStudentBatch institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/client/list-clients" exact component={ListClients} />
                        <Route path="/client/partially/registered" exact component={PreClients} />


                        <Route path="/manager/add-district-manager" exact component={AddSubAdmin} />



                        <Route path="/manager/list-managers" exact component={ListManagers} />
                        <Route path="/fee/types" exact component={FeeTypes} />
                        <Route path="/fee/levels" exact render={(props) => <LevelFee institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/courses" exact component={Courses} />
                        <Route path="/levels" exact render={(props) => <Levels institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/student/list-students" exact render={(props) => <ListStudents institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/graduate/list-graduates" exact render={(props) => <ListGraduates institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/student/info/:accountid" exact component={StudentInfo} />
                        <Route path="/graduate/info/:accountid" exact component={GraduateInfo} />
                        <Route path="/wallet/cashcollections/:reference" exact render={(props) => <CashCollection email={localStorage.getItem("email")} instituteId={localStorage.getItem(INSTITUTE_ID)} roles={localStorage.getItem(ROLES)} LipaNasiAccount={localStorage.getItem(LipaNasi_ACCOUNT)} institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/wallet/payments" exact render={(props) => <Payment LipaNasiAccount={localStorage.getItem(LipaNasi_ACCOUNT)} institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/wallet/district-payments" exact render={(props) => <DistrictPayment LipaNasiAccount={localStorage.getItem(LipaNasi_ACCOUNT)} institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/settlement/charges" exact component={SettlementCharges} />
                        <Route path="/payment/charges" exact component={PaymentCharges} />
                        <Route path="/wallet/settlements" exact render={(props) => <Settlements roles={localStorage.getItem(ROLES)} {...props}/>} />
                        <Route path="/print/payment/receipts" exact render={(props) => <PdfComponent institutionType={localStorage.getItem(CATEGORY)} {...props} />} />
                        <Route path="/wallet/list/whatsapp" exact render={(props) => <ListWhatsapp roles={localStorage.getItem(ROLES)}  instituteId={localStorage.getItem(INSTITUTE_ID)} {...props}/>} />
                        <Route path="/wallet/initiate/whatsapp" exact render={(props) => <InitiateWhatsapp  instituteId={localStorage.getItem(INSTITUTE_ID)} {...props} />} />

                        <Route path="/wallet/lga/reports/house-holder-payments" exact component={HouseHolderPayments} />
                        <Route path="/wallet/lga/reports/institutional-payments" exact render={(props) => <InstitutionPayments roles={localStorage.getItem(ROLES)}  districtName={localStorage.getItem(DISTRICT_ID)} {...props}/>} />
                        <Route path="/wallet/lga/reports/lga-analytics-report" exact component={LgaAnalyticsReport} />
                        <Route path="/wallet/lga/reports/lga-general-anlt-report" exact render={(props) => <LgaGeneralAnalyticalReport roles={localStorage.getItem(ROLES)}  districtName={localStorage.getItem(DISTRICT_ID)} {...props}/>} />
                        <Route path="/wallet/lga/reports/data/:dataId" exact component={InstitutionData} />
                        <Route path="/wallet/lga/reports/institution/list" exact component={InstitutionsList} />
                        <Route path="/wallet/create/push/ussd" exact render={(props) => <CreatePushUssd instituteId={localStorage.getItem(INSTITUTE_ID)} businessType={localStorage.getItem(BUSINESS_TYPE)} headerBalances={localStorage.getItem(HEADER_BALANCES)}  {...props}/>} />
                        <Route path="/wallet/pushUssd" exact render={(props) => <PushUssdPaymentList roles={localStorage.getItem(ROLES)} LipaNasiAccount={localStorage.getItem(LipaNasi_ACCOUNT)} institutionType={localStorage.getItem(CATEGORY)} {...props} />} />
                        <Route path="/wallet/list/collections" exact component={ListCollections} />
                        <Route path="/wallet/add/collection" exact component={AddCollections} />
                        <Route path="/wallet/collection/balances" exact component={CollectionBalance} />
                        <Route path="/wallet/gen/payment/reference" exact render={(props) => <CashPaymentReference headerBalances={localStorage.getItem(HEADER_BALANCES)} roles={localStorage.getItem(ROLES)} {...props}/>}/>
                        <Route path="/wallet/collect/cash" exact render={(props) => <CollectCash instituteId={localStorage.getItem(INSTITUTE_ID)} headerBalances={localStorage.getItem(HEADER_BALANCES)}  {...props}/>}/>
                        <Route path="/wallet/funds/transfer/:collectionId" exact component={FundsTransfer} />
                        <Route path="/wallet/collection/fees" exact component={CollectionFee} />
                        <Route path="/wallet/update/account" exact component={UpdateAccount} />
                        <Route path="/wallet/update/other/account" exact component={UpdateOtherAccount} />
                        <Route path="/wallet/balance/settlement" exact render={(props) => <BalanceSettlement headerBalances={localStorage.getItem(HEADER_BALANCES)} roles={localStorage.getItem(ROLES)} {...props} />}></Route>
                        <Route path="/wallet/extra/charges/settlement" exact render={(props) => <ExtraChargesSettlement headerBalances={localStorage.getItem(HEADER_BALANCES)} roles={localStorage.getItem(ROLES)} {...props} />}></Route>
                        <Route path="/sms/notifications" exact render={(props) => <Sms institutionType={localStorage.getItem(CATEGORY)} {...props} />}></Route>
                        <Route path="/sms/payments/history" exact component={PaymentHistory} />
                        <Route path="/wallet/list/groups" exact render={(props) => <ListGroups institutionType={localStorage.getItem(CATEGORY)} roles={localStorage.getItem(ROLES)} {...props} />} />
                        <Route path="/wallet/add/group" exact component={AddGroup} />
                        <Route path="/wallet/edit/group/:groupid" exact component={EditGroup} />
                        <Route path="/wallet/list/members" exact render={(props) => <ListMembers institutionType={localStorage.getItem(CATEGORY)} roles={localStorage.getItem(ROLES)} {...props} />} />
                        <Route path="/wallet/list/non-contributed/members" exact render={(props) => <MembersWithNoContributions institutionType={localStorage.getItem(CATEGORY)} roles={localStorage.getItem(ROLES)} {...props} />} />
                        <Route path="/wallet/add/member" exact component={AddMember} />
                        <Route path="/wallet/add/member/batch" exact component={ContributionMemberBatch} />
                        <Route path="/wallet/edit/member/:memberid" exact component={EditMember} />
                        <Route path="/wallet/list/contributions" exact component={ListContributions} />
                        <Route path="/wallet/lga/zones" exact component={Zones} />
                        <Route path="/wallet/lga/zone/:dataId" exact component={Zone} />
                        <Route path="/wallet/lga/cells" exact component={Cells} />
                        <Route path="/wallet/lga/cell/:dataId" exact component={Cell} />
                        <Route path="/wallet/lga/cell-leaders" exact component={CellLeaders} />
                        <Route path="/wallet/lga/cell-leader/:dataId" exact component={CellLeader} />
                        <Route path="/wallet/lga/roads" exact component={Roads} />
                        <Route path="/wallet/lga/road/:dataId" exact render={(props) => <Road instituteId={localStorage.getItem(INSTITUTE_ID)} {...props}/>} />
                        <Route path="/wallet/lga/house/holders" exact component={HouseHolders} />
                        <Route path="/wallet/lga/house/holder/:dataId" exact component={HouseHolder} />
                        <Route path="/wallet/lga/month/bills/:dataId" exact component={MonthBills} />
                        <Route path="/wallet/lga/month/cell/bills/:cellId" exact component={CellPayments} />
                        <Route path="/wallet/lga/month/road/bills/:roadId" exact component={RoadPayments} />

                        <Route path="/wallet/lga/house/holders/batch" exact component={HouseHolderBatch} />
                        <Route path="/wallet/lga/house/bills" exact component={HouseBills} />
                        <Route path="/wallet/lga/pay/transfer/:dataId/:paymentId" exact component={PayTransfer} />
                        <Route path="/wallet/lga/pay/transfers" exact component={PayTransfers} />
                        <Route path="/wallet/lga/tenants/month/bills/:dataId" exact component={TenantsMonthBills} />
                        <Route path="/wallet/lga/tenants/:dataId" exact component={LgaTenants} />
                        <Route path="/wallet/lga/tenant/:dataId/:houseHolderId" exact component={LgaTenant} />
                        <Route path="/wallet/lga-tenants/batch" exact component={LgaTenantsBatch}/>
                        <Route path="/wallet/lga/employees" exact component={LgaEmployees} />
                        <Route path="/wallet/lga/employee/:dataId" exact component={LgaEmployee} />
                        <Route path="/wallet/lga/salaries" exact component={LgaSalaries} />
                        <Route path="/wallet/lga/salaries/disburse" exact component={LgaDisburseSalaries} />
                        <Route path="/wallet/real/estate/projects" exact component={Projects} />
                        <Route path="/wallet/real/estate/project/:dataId" exact component={Project} />
                        <Route path="/wallet/real/estate/customers" exact component={Tenants} />
                        <Route path="/wallet/real/estate/customer/:dataId" exact component={Tenant} />

                        <Route path="/wallet/lga/crises/:institutionId" exact render ={(props) => <Crises roles={localStorage.getItem(ROLES)} districtName={localStorage.getItem(DISTRICT_ID)} instituteName={localStorage.getItem(INSTITUTE_NAME)} instituteId={localStorage.getItem(INSTITUTE_ID)} districtId={localStorage.getItem(DISTRICT_ID)}  {...props}/>} />
                        <Route path="/wallet/lga/add/crises" exact render={(props) => <AddCrises instituteId={localStorage.getItem(INSTITUTE_ID)} {...props}/>}/>
                        <Route path="/wallet/lga/crises/details/:crisesId" exact component={CrisesInfo} />

                        <Route path="/wallet/report/transacions-list" exact render={(props) => <GeneralTransactions roles={localStorage.getItem(ROLES)} {...props}/>}/>
                        <Route path="/wallet/report/daily-report" exact render={(props) => <DailyTransactions roles={localStorage.getItem(ROLES)} {...props}/>}/>
                        <Route path="/wallet/report/charges-list" exact render={(props) =><ChargesReport roles={localStorage.getItem(ROLES)} {...props}/>}/>

                        <Route path="/wallet/real/estate/sales" exact component={ProjectSales} />
                        <Route path="/wallet/real/estate/sale/:dataId" exact component={ProjectSale} />
                        <Route path="/wallet/real/estate/payments" exact component={ProjectPayments} />
                        <Route path="/wallet/real/estate/installment/:dataId/:customerFeeId" exact component={Installment} />
                        <Route path="/wallet/real/estate/installments/:dataId" exact component={installments} />
                        <Route path="/wallet/businesses" exact component={OtherBusinessType} />
                        <Route path="/wallet/business/category/:dataId" exact component={AddBusiness} />
                        <Route path="/wallet/business/add/customer" exact component={AddCustomer} />
                        <Route path="/wallet/business/edit/customer/:customerid" exact component={EditCustomer} />
                        <Route path="/wallet/business/list/customers" exact component={ListCustomers} />
                        <Route path="/wallet/business/archived/customers" exact component={ArchivedCustomers} />
                        <Route path="/wallet/business/transactions/:businessId" exact component={Transactions} />
                        <Route path="/client/list/documents" exact component={Documents} />
                        <Route path="/client/update/document/:docType" exact component={UpdateDocument} />
                        <Route path="/client/approve/documents/:clientid" exact component={ApproveDocuments} />
                        <Route path="/client/sms/notifications" exact component={Sms} />
                        <Route path="/partner/add/partners" exact component={AddPartner} />
                        <Route path="/partner/list/partners" exact component={ListPartners} />
                        <Route path="/user/add/staff" exact component={AddStaff} />
                        <Route path="/user/add/agent" exact component={AddAgent} />
                        <Route path="/user/list/agents" exact component={ListAgents} />
                        <Route path="/user/list/staffs" exact component={ListStaffs} />
                        <Route path="/user/list/users" exact component={ListUsers} />
                        <Route path="/user/roles" exact component={ListUserRoles} />
                        <Route path="/user/reseting/password" exact component={ResetingPassword} />
                    </div>
                    {/* <AppFooter /> */}
                    {this.state.staticMenuMobileActive && <div className="layout-mask"></div>}
                </div>
                <Route path="/login" render={(props) => <Login authenticated={this.state.authenticated} {...props} />}></Route>
                <Route path="/galaxy/:token" component={Galaxy} />
            </div>
        );
    }
}

Zones.propTypes = {
    t: i18n
}

export default withTranslation()(App);
