import { PARTNER, PARTNER_DETAILS } from '../api';
import { API_BASE_URL } from '../constants';
import { postForms, postRequest, getRequest, queryParams } from './CoreService';

export class UserService {

    signingUp(userJson) {
        return postRequest({
            url: API_BASE_URL + "/user/sign/up",
            method: 'POST',
            body: JSON.stringify(userJson)
        });
    }

    completeRegistration(clientJson) {
        return postRequest({
            url: API_BASE_URL + "/client/complete/registration",
            method: 'POST',
            body: JSON.stringify(clientJson)
        });
    }

    addRole(role) {
        return postRequest({
            url: API_BASE_URL + "/user/add/role",
            method: 'POST',
            body: JSON.stringify(role)
        });
    }

    addUser(user, roles) {
        const formData = new URLSearchParams();
        formData.append('user', user)
        formData.append('roles', roles);
        return postForms({
            url: API_BASE_URL + "/user/add",
            method: 'POST',
            body: formData
        });
    }

    addUserRole(userId, roleId) {
        const formData = new URLSearchParams();
        formData.append('user_id', userId)
        formData.append('role_id', roleId);
        return postForms({
            url: API_BASE_URL + "/user/add/user/role",
            method: 'POST',
            body: formData
        });
    }

    removeUserRole(userId, roleId) {
        const formData = new URLSearchParams();
        formData.append('user_id', userId)
        formData.append('role_id', roleId);
        return postForms({
            url: API_BASE_URL + "/user/remove/user/role",
            method: 'POST',
            body: formData
        });
    }

    // resetPassword(code, password) {
    //     const formData = new URLSearchParams();
    //     formData.append('code', code)
    //     formData.append('password', password);
    //     return postForms({
    //         url: API_BASE_URL + "/user/reset/password",
    //         method: 'POST',
    //         body: formData
    //     });
    // }

    resetPassword(userJson) {
        return postRequest({
            url: API_BASE_URL + "/user/reset/password",
            method: 'POST',
            body: JSON.stringify(userJson)
        });
    }

    accountActivation(userJson) {
        return postRequest({
            url: API_BASE_URL + "/user/activate/account",
            method: 'POST',
            body: JSON.stringify(userJson)
        });
    }

    resendActivationCode(userJson) {
        return postRequest({
            url: API_BASE_URL + "/user/resend/activation/code",
            method: 'POST',
            body: JSON.stringify(userJson)
        });
    }

    getUsers(fullName, email, phoneNumber, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/user/all/users/paginated?fullName=" + fullName + "&email=" + email + "&phoneNumber=" + phoneNumber + "&page=" + page + "&size=" + limit + "&sort=" + sort + "&direction=" + order,
            method: 'GET'
        });
    }

    getAllUsers(institutionId){
        return getRequest({
            url: API_BASE_URL + "/user/all/users/institutionId?institutionId=" + institutionId,
            method: 'GET'
        });
    }

    getRoles() {
        return getRequest({
            url: API_BASE_URL + "/user/all/roles",
            method: 'GET'
        });
    }

    addStaff(staffJson) {
        const formData = new URLSearchParams();
        formData.append('user', JSON.stringify(staffJson));
        return postForms({
            url: API_BASE_URL + "/user/add/staff",
            method: 'POST',
            body: formData
        });
    }

    addAgent(agentJson) {
        const formData = new URLSearchParams();
        formData.append('user', JSON.stringify(agentJson));
        return postForms({
            url: API_BASE_URL + "/user/add/agent",
            method: 'POST',
            body: formData
        });
    }

    getStaffs(jsonParams) {
        return getRequest({
            url: API_BASE_URL + "/user/get/staffs" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getAgents(jsonParams) {
        return getRequest({
            url: API_BASE_URL + "/user/get/agents" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addPartner(clientJson) {
        return postRequest({
            url: PARTNER_DETAILS,
            method: 'POST',
            body: JSON.stringify(clientJson)
        });
    }

    updatePartner(clientJson) {
        return postRequest({
            url: PARTNER_DETAILS,
            method: 'PUT',
            body: JSON.stringify(clientJson)
        });
    }

    getPartnerDetails(partnerId) {
        return getRequest({
            url: PARTNER_DETAILS + "?partnerId=" + partnerId,
            method: 'GET'
        });
    }

    getPartners(partnerName, LipaNasiCode, emailAddress, phoneNumber, page, limit, sort, order) {
        return getRequest({
            url: PARTNER + "/list?partnerName=" + partnerName + "&LipaNasiCode=" + LipaNasiCode 
            + "&emailAddress=" + emailAddress + "&phoneNumber=" + phoneNumber 
            + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getPartnerCategories(categoryName, page, limit, sort, order) {
        return getRequest({
            url: PARTNER + "/categories?categoryName=" + categoryName 
            + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
}