import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { WalletService } from '../../service/WalletService';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { withTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { HomeService } from '../../service/HomeService';
import { ClientService } from '../../service/ClientService';



class ExtraChargesSettlement extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            settlementAmount: 0,
            settlementPrompt: "Withdraw Extra Charges Balance",
            chargesTypeSelected: false,
            institutionSelected: false,
            chargesType: "choose",
            chargesTypes: [{label: "Datavision Charges",value: "DVI_CHARGES"},{label: "Institution Charges",value: "INSTITUTE_CHARGES"},{label: "Choose Charges Types",value: "choose"}],
            institutions: [],
            institutionId: null,
            details: "",
            alert: '',
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            sortOrder: -1,
            sortField: 'id',
            regionId: null,
            regions: [],
            categoryId: null,
            categories: [],
            partnerId: null,
            partners: [],
            instituteName: '',
            instituteCode: '',
            LipaNasiAccount: '',
        }

        this.walletService = new WalletService();
        this.clientService = new ClientService();
        this.homeService = new HomeService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChargesTypes = this.handleChargesTypes.bind(this);
        this.handleInstitutionChanges = this.handleInstitutionChanges.bind(this);
        
    }
    componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.fetchData();      
    }

    async  fetchData() {
        try {            
          const response = await this.clientService.getAllClients(this.state.pageIndex, this.state.pageSize, this.state.sortField, 'asc');
         
          const option = response.map((d,i) => ({
            "value": d.id,
            "label": d.instituteName + "-"+d.instituteCode
          }))
          this.setState({institutions: option});
        //   console.log("Institutons", this.state.institutions);
        } catch (error) {
          this.setState({alert: error.message});
          console.error('Error fetching data:', error);
        }
      }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const settlementJson = {
            settlementAmount: this.state.settlementAmount,
            details: this.state.details,institutionId: this.state.institutionId
        };

        if(this.state.chargesType === "DVI_CHARGES"){

            if (JSON.parse(this.props.headerBalances).dviCharges > settlementJson.settlementAmount) {
                this.walletService.createExtraChargesSettlement(settlementJson,this.state.chargesType)
                .then(response => {
                    this.setState({failed: false});
                    this.setState({alert: response.message});
                    this.props.history.push("/wallet/settlements");
                }).catch(error => {
                    this.setState({failed: true});
                    this.setState({alert: error.message});
                });
            } else {
                this.setState({failed: true});
                this.setState({alert: "Settlement Amount must be lower than your balance"});
            }

        }else if(this.state.chargesType === "INSTITUTE_CHARGES"){
            if (JSON.parse(this.props.headerBalances).instCharges > settlementJson.settlementAmount) {
                this.walletService.createExtraChargesSettlement(settlementJson,this.state.chargesType)
                .then(response => {
                    this.setState({failed: false});
                    this.setState({alert: response.message});
                    this.props.history.push("/wallet/settlements");
                }).catch(error => {
                    this.setState({failed: true});
                    this.setState({alert: error.message});
                });
            } else {
                this.setState({failed: true});
                this.setState({alert: "Settlement Amount must be lower than your balance"});
            }

        }else{

            this.setState({failed: true});
            this.setState({alert: "Settlement Failed, Invalid Charges Type selected"});
        }


        
	}

    handleChargesTypes(event){
        const value = event.target.value;
        this.setState({chargesType: value});
        if(value !== "choose"){
            this.setState({chargesTypeSelected:true});
            this.state.chargesTypes.map(data => {
                if(data.value === value){
                    this.setState({settlementPrompt: "Withdraw "+data.label});
                }                
            });
            
        }else{
            this.setState({chargesTypeSelected:false}); 
        }        
    }

    handleInstitutionChanges(event){
        const value = event.target.value;
        this.setState({institutionId: value});
        if(value !== null){
          this.setState({institutionSelected: true});
        }else{
            this.setState({institutionSelected: false});
        }

        // console.log("INST SLECTD: "+this.state.institutionSelected);
    }

    render() { 
        if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/extra/charges/settlement",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#3B007B", color: "#FFFFFF", padding: "1%"}}>
                                <h2 style={{textAlign: "center"}}>{this.props.t(this.state.settlementPrompt)}</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>

                             <div className="p-grid">
                            <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Charges Type')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.chargesTypes} value={this.state.chargesType} onChange={this.handleChargesTypes} required/>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Institution Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                        <Dropdown options={this.state.institutions} value={this.state.institutionId} onChange={this.handleInstitutionChanges} filter={true} required/>
                                        </div>
                                    </div>

                            </div>

                            

                           {this.state.chargesTypeSelected && this.state.institutionSelected && <div><div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Withdraw Amount')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputNumber type="text" name="settlementAmount" placeholder={this.props.t("Enter") + " " + this.props.t("Amount")} value={this.state.settlementAmount} mode="decimal" minFractionDigits={2} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Withdraw Details')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="details" placeholder={this.props.t("Enter") + " " + this.props.t("Details")} value={this.state.details} mode="decimal" minFractionDigits={2} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t("Confirm Withdraw")} icon="pi pi-plus" />
                                    </div>
                                </div></div>} 

                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withTranslation()(ExtraChargesSettlement);