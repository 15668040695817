import { API_BASE_URL } from '../constants';
import { loginRequest, postRequest, getRequest } from './CoreService';

export function login(username, password) {
    // const formData = new URLSearchParams();
    // formData.append('username', username);
    // formData.append('password', password);
    // formData.append('grant_type', "password");
    // return loginRequest({
    //     url: API_BASE_URL + "/oauth/token",
    //     method: 'POST',
    //     body: formData
    // });


    const credentialJson = {
        username: username,
        password: password
    }
    return loginRequest({
        url: API_BASE_URL + "/api/auth/login",
        method: 'POST',
        body: JSON.stringify(credentialJson) //credentialJson
    });
}

export function getCurrentUser() {
    return getRequest({
        url: API_BASE_URL + "/user/info",
        method: 'GET'
    });
}

export function signout() {
    return getRequest({
        url: API_BASE_URL + "/user/logout",
        method: 'GET'
    });
}

export function signup(signupRequest) {
    return postRequest({
        url: API_BASE_URL + "/user/sign/up",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}

export function passwordResetRequest(restJson) {
    return postRequest({
        url: API_BASE_URL + "/home/password/reset/request",
        method: 'POST',
        body: JSON.stringify(restJson)
    });
}

export function resetPassword(restJson) {
    return postRequest({
        url: API_BASE_URL + "/home/reset/password",
        method: 'POST',
        body: JSON.stringify(restJson)
    });
}