import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import ResponseAlert from '../Utilities/ResponseAlert';
import { resetPassword } from '../service/LoginService';

export const ResetPassword = () => {

    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    const [alert, setAlert] = useState("");
    const [failed, setFailed] = useState(null);

	const history = useHistory();

	const goDashboard = () => {
		history.push('/');
	}

    const handleSubmit = (e) => {
        e.preventDefault();
        const resetJson = {
            password: password,
            confirm: confirm,
            code: code
        }
        if (password === confirm) {
            resetPassword(resetJson)
            .then(response => {
                setFailed(false);
                setAlert("Password reset successfully!");
                goDashboard();
            }).catch(error => {
                setFailed(true);
                setAlert("Failed to reset password!");
            });
        } else {
            setFailed(true);
            setAlert("Password does not match!");
        }
	}

    const goToLogin = () => {
        history.push("/login");
    }

	return (
        <div className="p-grid" style={{height: "56.5em", backgroundColor: "#f5f5f5", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", overflow: "hidden"}}>
            <div className="login-wrapper" style={{overflow: "hidden"}}>
                <div className="login-container" style={{border: "solid #8882BD", marginTop: "10em", paddingTop: "2em", paddingBottom: "2em", backgroundColor: "#ffffff"}}>
                    <img src="assets/layout/images/lipanasi.jpeg" alt="LipaNasi" className="logo" style={{padding: "0px", width: "8em"}} />
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-text-center" style={{textAlign: "center"}}>
                            <span className="title p-text-center" style={{color: "#00a14b", textAlign: "center"}}>Reset Password</span>
                        </div>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-10 p-offset-1 p-text-center" style={{textAlign: "center"}}>
                            <ResponseAlert failed={failed} alert={alert} setFailed={setFailed} />
                        </div>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-10 p-offset-1">
                                {/* <span className="p-float-label"> */}
                                    <InputText type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} required placeholder="Password" />
                                    {/* <label htmlFor="password">Password</label>
                                </span> */}
                            </div>
                            <div className="p-col-10 p-offset-1">
                                {/* <span className="p-float-label"> */}
                                    <InputText type="password" name="confirm" value={confirm} onChange={e => setConfirm(e.target.value)} required placeholder="Confirm" />
                                    {/* <label htmlFor="confirm">Confirm</label>
                                </span> */}
                            </div>
                            <div className="p-col-10 p-offset-1">
                                {/* <span className="p-float-label"> */}
                                    <InputText type="text" name="code" value={code} onChange={e => setCode(e.target.value)} required placeholder="Verification Code" />
                                    {/* <label htmlFor="code">Verification Code</label>
                                </span> */}
                            </div>
                            <div className="p-col-10 p-offset-1">
                                <Button type="submit" label="Reset Password" icon="pi pi-check" autoFocus={true}/>
                            </div>
                            <div className="p-col-10 p-offset-1">
                                <Button type="button" label="Back to Login" onClick={()=>goToLogin()} icon="pi pi-fw pi-arrow-circle-left"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
	)
}
