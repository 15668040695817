import React,{Component} from 'react';


import './styles.module.css';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import adalipaLogo from '../../assets/layout/images/adalipa_logo_01.png';
import { WalletService } from '../../service/WalletService';
import { UserService } from '../../service/UserService';

const centeredStyle = {
    centered: {
        textAlign: "center",
        alignContent: 'center',
    },
  };

const ticketStyles = {
    ticket: {
        width: '155px',
        maxWidth: '155px',
        textAlign: 'center',
    }
    
};

const columnStyles = {
    description: {
        width: '60px',
        maxWidth: '60px',
        textAlign: 'left',
        // wordBreak: 'break-all',
    },   
    price: {
        width: '95px',
        maxWidth: '95px',
        textAlign: 'left',
        // wordBreak: 'break-all',
    },
    receipt: {
        width: '105px',
        maxWidth: '205px',
        // wordBreak: 'break-all',
    },
};

const trStyles = {
    tr:{
        height: '30px'        
    }
    
}

const logoStyle = {
    logo: {
        marginLeft:"10px",
        width: "90px",
        textAlign: "center",
        alignContent: 'center',
    }
}


class DataComponent extends Component {
    constructor(props){
        super(props)
        this.state = { 
            managerPhone: ""
         } 
        this.adalipaUtil = new AdalipaUtil();
        this.walletService = new WalletService();
        this.userService = new UserService();
        this.containsAlphabet = this.containsAlphabet.bind(this);
        this.formatAmount = this.formatAmount.bind(this);
        this.FormatAmountWithTwoDecimal = this.FormatAmountWithTwoDecimal.bind(this);
    }

  

  containsAlphabet = (str) => {
    return /[a-zA-Z]/.test(str);
}

formatAmount = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
FormatAmountWithTwoDecimal = (amount) => {
    
      return amount.toLocaleString('en-US', {
        style: 'decimal',  // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
}

   

    // componentDidMount(){        
    //     console.log("Institution XXXXXX",this.props.institutionId);
    //     this.userService.getAllUsers(this.props.institutionId)
    //     .then(resp => {
    //        this.setState({managerPhone: resp.phoneNumber})
    //     })
    //     .catch(error => {

    //     })    

    // }

    render() { 
        return (
            <div className="">
            <div className="ticket" style={ticketStyles.ticket}>
            <img src={adalipaLogo} alt="Logo" style={logoStyle.logo}/>
            {/* <img src={houseExcel} alt="LipaNasi" className="logo"/> */}
            {/* <p className="centered">RECEIPT EXAMPLE
                <br>Address line 1
                <br>Address line 2</p> */}
                <p className="centered" style={centeredStyle.centered}><b>RISITI YA MALIPO</b>
                <br/>ULINZI SHIRIKISHI
                <br/>
                <br/>{this.props.mtaa} 
                <br/>{this.props.managerPhoneNumber}</p>
            <table>
               
                <tbody>
                    <tr>
                        
                        <td className="description" style={columnStyles.description}>Risiti:</td>
                        <td className="price" style={columnStyles.price}><b>{this.props.receiptNumber.length > 12 ? this.props.receiptNumber.substring(0, this.props.receiptNumber.length -2).toLowerCase() : (this.props.receiptNumber.length === 12 && this.containsAlphabet(this.props.receiptNumber)) ? this.props.receiptNumber.substring(0, this.props.receiptNumber.length -2).toLowerCase() : this.props.receiptNumber}</b></td>
                    </tr>
                    <tr className='tr' style={trStyles.tr}> 
                        
                        <td className="description" style={columnStyles.description}>Tarehe:</td>
                        <td className="price" style={columnStyles.price}><b>{this.adalipaUtil.cleanDateFormat(this.props.receiptDate)}</b></td>
                    </tr>
                    <tr className='tr' style={trStyles.tr}>
                        
                        <td className="description" style={columnStyles.description}>Muda:</td>
                        <td className="price" style={columnStyles.price}><b>{this.adalipaUtil.timeFormat(this.props.receiptDate)}</b></td>
                    </tr>
                    <tr className='tr' style={trStyles.tr}>
                        
                        <td className="description" style={columnStyles.description}>Simu:</td>
                        <td className="price" style={columnStyles.price}><b>{this.props.phoneNumber}</b></td>
                    </tr>
                    <tr className='tr' style={trStyles.tr}>
                        <td className="description" style={columnStyles.description}>Kumb:</td>
                        <td className="price" style={columnStyles.price}><b>{this.props.accountNumber}</b></td>
                    </tr>
                    <tr className='tr' style={trStyles.tr}>
                        <td className="description" style={columnStyles.description}>Kiasi:</td>
                        <td className="price" style={columnStyles.price}><b>{this.FormatAmountWithTwoDecimal(this.props.amount)}</b></td>
                    </tr>

                   <tr className='tr' style={trStyles.tr}>
                        <td className="description" style={columnStyles.description}>Makato:</td>
                        <td className="price" style={columnStyles.price}><b>{this.FormatAmountWithTwoDecimal(this.props.charges)}</b></td>
                    </tr> 

                    <tr className='tr' style={trStyles.tr}>
                        <td className="description" style={columnStyles.description}>Jumla:</td>
                        <td className="price" style={columnStyles.price}><b>{(this.FormatAmountWithTwoDecimal(this.props.charges+this.props.amount))}</b></td>
                    </tr>
                </tbody>
            </table>
            <p class="centered" style={centeredStyle.centered}>Asante kwa kutumia Adalipa!</p>
        </div>
        {/* <button id="btnPrint" className="hidden-print">Print</button>
        <script src="script.js"></script> */}
        </div>
        );
    }
}
 
export default DataComponent;