import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { ACCESS_TOKEN, FULL_NAME, EMAIL, ROLES, INSTITUTE_NAME, CATEGORY, LipaNasi_ACCOUNT, HEADER_BALANCES, INSTITUTE_STATUS } from '../constants';
import { getCurrentUser } from '../service/LoginService';
import { ClientService } from '../service/ClientService';

export default class Galaxy extends Component {
	constructor(props) {
        super(props);
        this.clientService = new ClientService();
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.storeToken();
    }

    storeToken() { 
        if(this.props.match.params.token !== "none" && this.props.match.params.token !== "") {
            localStorage.setItem(ACCESS_TOKEN, this.props.match.params.token);
			this.getUserInfo();
        }
	}
	
	getUserInfo() {
		getCurrentUser()
		.then(response => {
			localStorage.setItem(FULL_NAME, response.fullName);
			localStorage.setItem(EMAIL, response.username);
			localStorage.setItem(ROLES, JSON.stringify(response.roles));
			localStorage.setItem(INSTITUTE_NAME, response.institution !== null ? response.institution.instituteName : '');
			localStorage.setItem(CATEGORY, response.institution !== null ? response.institution.category.institutionType.typeName : '');
			localStorage.setItem(LipaNasi_ACCOUNT, response.institution !== null ? response.institution.LipaNasiAccount : '');
			localStorage.setItem(INSTITUTE_STATUS, response.institution !== null ? response.institution.status : '');
			this.setState({fullName: response.fullName});
			this.clientService.getBalances()
			.then(response => {
				localStorage.setItem(HEADER_BALANCES, JSON.stringify(response));
				this.props.history.push("/");
			}).catch(error => {
				console.log("Failed to get header balances");
			})
		}).catch(error => {
			console.log("Failed to get current user details");
			this.props.history.push("/login");
		})
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="exception-body access-body">
				<div className="exception-container">
					<img src="assets/layout/images/logo-dark.png" alt="apollo-layout"/>
					<h1>Loading...</h1>
					<p>
						System is assesing your privileges.
					</p>
                </div>
            </div>
        );
	}
}
