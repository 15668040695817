 export const API_BASE_URL = 'https://pictz.xyz:8443';
// export const ADALIPA_BASE_URL = 'http://154.74.133.74:8844/adalipa';
// export const API_BASE_URL = 'http://154.74.133.74:8844/adalipa';
//export const API_BASE_URL = 'http://localhost:8080';
// export const API_BASE_URL = 'http://154.74.133.74:8443';
export const ACCESS_TOKEN = 'accessToken';
export const TOKEN_TYPE = 'tokenType';
export const FULL_NAME = 'fullName';
export const INSTITUTE_NAME = 'instituteName';
export const INSTITUTE_ID = 'instituteId';
export const LipaNasi_ACCOUNT = 'LipaNasiAccount';
export const CATEGORY = 'institutionType';
export const INSTITUTE_STATUS = 'instituteStatus';
export const DISTRICT_ID = 'districtName';
export const BUSINESS_TYPE = "businessType";
export const EMAIL = 'email';
export const ROLES = 'roles';
export const REFRESH_TOKEN = 'refreshToken';
export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect';
export const HEADER_BALANCES = 'headerBalances';